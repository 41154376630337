import React from "react";
import { Container, Inner } from "./style";

const Section = ({ id, children, ...props }) => {
  return (
    <Container id={id} {...props}>
      <div className="section-inner">{children}</div>
    </Container>
  );
};

const SectionTitle = ({ title, subTitle, children }) => {
  return (
    <Inner>
      <div className="section-title">
        <h2>{title}</h2>
        {subTitle && <h3>{subTitle}</h3>}
      </div>
      <div className="section-main">{children}</div>
    </Inner>
  );
};

Section.Title = SectionTitle;

export default Section;
