import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Section from "../../components/Section";
import styled from "styled-components";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import ReactHelmet from "../../components/common/ReactHelmet";
import NewsMainImg from "../../asset/images/news_main_image.jpg";
import styles from "./news.module.css";
import NavBottom from "../../components/NavBottom";

const News = ({ match }) => {
  const [news, setNews] = useState([]);
  const { i18n } = useTranslation();
  const [isRendering, setisRendering] = useState(false);

  useEffect(() => {
    setisRendering(true);
    AOS.init({
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    setNews([
      {
        index: 1,
        title: "2023 정기주주총회 소집공고",
        date: "2023-03-13",
      },
    ]);
  }, []);
  return (
    <>
      <ReactHelmet
        keywords={
          i18n.language === "ko"
            ? "에이아이에스(AIS), 잘키움, 새소식"
            : "에이아이에스(AIS), 잘키움, News"
        }
        description={
          i18n.language === "ko"
            ? "에이아이에스(AIS)의 새소식을 알려드립니다"
            : "News from 에이아이에스(AIS)"
        }
        title={
          i18n.language === "ko"
            ? "에이아이에스(AIS) | 새소식"
            : "에이아이에스(AIS) | News"
        }
        image={NewsMainImg}
        url={
          i18n.language === "ko"
            ? "www.aiskorea.co.kr/ko/news"
            : "www.aiskorea.co.kr/en/news"
        }
      />
      {isRendering && (
        <>
          <section>
            <div className={styles.newMain} />
            <div
              style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                color: "white",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
              }}
            >
              <p
                className={styles.mainTitle}
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                {i18n.t("news.newsMainTitle1")}
              </p>
              <p
                data-aos="fade-up"
                data-aos-duration="2000"
                className={styles.mainSubTitle}
              >
                {i18n.t("news.newsMainTitle3")}
              </p>
            </div>
          </section>

          <Section id="notices-release">
            <Section.Title
              title="공지사항"
              subTitle="에이아이에스에서 알립니다"
            >
              <Container>
                <div className="notices-wrapper">
                  <ul className="notices-items">
                    {news.map((data) => {
                      return (
                        <NewsItem
                          lang={match.params.lang}
                          index={data.index}
                          date={data.date}
                          title={data.title}
                        />
                      );
                    })}
                  </ul>
                </div>
                <BoardNav>
                  <Link>
                    <span className="arrow">{"<"}</span>
                  </Link>
                  <Link>1</Link>
                  <Link>
                    <span className="arrow">{">"}</span>
                  </Link>
                </BoardNav>
              </Container>
            </Section.Title>
          </Section>
          <NavBottom />
        </>
      )}
    </>
  );
};

export default News;

const NewsItem = ({ lang, index, title, date }) => {
  return (
    <NoticeItem>
      <Link to={`/${lang}/news/board/${index}`}>
        <div className="notice-row">
          <div>{index}</div>
          <div>{title}</div>
          <div>{date}</div>
        </div>
      </Link>
    </NoticeItem>
  );
};

export const Container = styled.div`
  .notices-wrapper {
    padding: 15px 0;

    .notices-items {
      width: 100%;
      border-top: 1px solid #e9e9e9;
    }
  }
`;

const NoticeItem = styled.li`
  width: 100%;
  border-bottom: 1px solid #e9e9e9;

  &:hover {
    background: #f4f8f8;
  }

  .notice-row {
    display: flex;
    align-items: center;
    div {
      padding: 22px;
      &:nth-child(1) {
        width: 10%;
        font-size: 0.8rem;
        font-weight: normal;
      }

      &:nth-child(2) {
        width: 70%;
        font-size: 0.8rem;
      }

      &:nth-child(3) {
        width: 20%;
        font-size: 0.8rem;
        font-weight: normal;

        @media screen and (max-width: 767px) {
          width: 30%;
        }
      }
    }
  }
`;

const BoardNav = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5em;
  font-size: 0.8rem;

  .arrow {
    padding: 1em;
  }
`;
