import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;

  .section-inner {
    width: 100%;
  }
`;

export const Inner = styled.div`
  padding-top: 80px;
  width: 100%;

  h2 {
    font-size: 1.8rem;
    margin-bottom: 8px;
  }

  h3 {
    font-size: 1rem;
    margin-bottom: 16px;
  }

  .section-title {
    width: 70%;
    margin: auto;

    @media screen and (max-width: 767px) {
      width: 80%;
    }
  }

  .section-main {
    width: 70%;
    margin: auto;

    @media screen and (max-width: 767px) {
      width: 80%;
    }
  }
`;
