import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import styled from 'styled-components';

export const NdviMap = ({
  isReadOnly,
  imageUrl,
  isStatic,
  locations,
  isFirst,
}) => {
  const mapRef = useRef(null);

  const initMap = useCallback((mapRef) => {
    /* eslint-disable */
    var container = L.DomUtil.get('map');
    if (container != null) {
      container._leaflet_id = null;
    }

    /* eslint-disable */
    var map = L.map('map', {
      zoomControl: true,
      maxZoom: 28,
      minZoom: 1,
      zoom: 18,
    }).fitBounds(
      locations
        ? locations
        : [
            [36.88884, 127.81907],
            [36.89044, 127.82137],
          ]
    );
    map.attributionControl.setPrefix(
      null
      // '<a href="https://github.com/tomchadwin/qgis2web" target="_blank">qgis2web</a> &middot; <a href="https://leafletjs.com" title="A JS library for interactive maps">Leaflet</a> &middot; <a href="https://qgis.org">QGIS</a>'
    );
    var bounds_group = new L.featureGroup([]);
    function setBounds() {}
    map.createPane('pane_VWorldSatellite_0');
    map.getPane('pane_VWorldSatellite_0').style.zIndex = 400;
    var layer_VWorldSatellite_0 = L.tileLayer(
      // "https://xdworld.vworld.kr:8080/2d/Satellite/service/{z}/{x}/{y}.jpeg"
      'https://api.vworld.kr/req/wmts/1.0.0/FD40AE16-A77B-35B4-BC36-4C5F5C80E7A8/Satellite/{z}/{y}/{x}.jpeg',
      // "http://api.vworld.kr/req/wms?key=FD40AE16-A77B-35B4-BC36-4C5F5C80E7A8&domain=https://www.aiskorea.co.kr/2d/Satellite/service/{z}/{x}/{y}.jpeg",
      // "https://api.vworld.kr/req/wmts/1.0.0/FD40AE16-A77B-35B4-BC36-4C5F5C80E7A8/Satellite/service/{z}/{x}/{y}.jpeg",
      {
        pane: 'pane_VWorldSatellite_0',
        opacity: 1.0,
        attribution: '',
        minZoom: 1,
        maxZoom: 28,
        minNativeZoom: 0,
        maxNativeZoom: 18,
      }
    );
    //   var cadastral = L.tileLayer.wms("http://api.vworld.kr/req/wms", {
    //     "version": "1.3.0",
    //     "layers": "lp_pa_cbnd_bonbun,lp_pa_cbnd_bubun",
    //     "styles": "lp_pa_cbnd_bonbun,lp_pa_cbnd_bubun,lp_pa_cbnd_bonbun_line,lp_pa_cbnd_bubun_line",
    //     "format": "image/png",
    //     "transparent": true,
    //     "opacity": 1.0,
    //     "maxZoom": 22,
    //     "maxNativeZoom": 19,
    //     "key": "FD40AE16-A77B-35B4-BC36-4C5F5C80E7A8",
    //     "domain": "https://www.aiskorea.co.kr/"
    // }).addTo(map);
    layer_VWorldSatellite_0;
    map.addLayer(layer_VWorldSatellite_0);
    map.createPane('pane_0812_cal_1');
    map.getPane('pane_0812_cal_1').style.zIndex = 401;
    var img_0812_cal_1 = imageUrl
      ? imageUrl
      : 'https://openapi.wellgrower.co.kr/images/wellgrower/ndvi/1/20220413.png';
    var img_bounds_0812_cal_1 = locations
      ? locations
      : [
          [36.88904, 127.81951],
          [36.89012, 127.82087],
          // [36.88884, 127.81907],
          // [36.89044, 127.82137],
        ];
    var layer_0812_cal_1 = new L.imageOverlay(
      img_0812_cal_1,
      img_bounds_0812_cal_1,
      { pane: 'pane_0812_cal_1' }
    );
    bounds_group.addLayer(layer_0812_cal_1);
    map.addLayer(layer_0812_cal_1);
    setBounds();
    L.ImageOverlay.include({
      getBounds: function () {
        return this._bounds;
      },
    });
  }, []);

  const loadMapScript = useCallback(() => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = `/resources/leaflet.js`;
      script.defer = true;
      document.head.appendChild(script);

      const cssFile = document.createElement('link');
      cssFile.href = '/resources/leaflet.css'; // your css file path
      cssFile.rel = 'stylesheet';
      document.head.appendChild(cssFile);

      script.onload = () => {
        if (mapRef.current) {
          initMap(mapRef);
          window?.ReactNativeWebView?.postMessage(
            JSON.stringify({
              onLoaded: true,
            })
          );
        }
        resolve();
      };
    });
  }, [initMap]);

  const loadMap = async () => {
    await loadMapScript();
  };

  useEffect(() => {
    // eslint-disable-next-line
    (isReadOnly || isFirst) && loadMap();
  }, [isReadOnly, isFirst, loadMap]);

  useEffect(() => {
    // eslint-disable-next-line
    if (!isFirst && imageUrl && locations) {
      initMap(mapRef);
    }
  }, [imageUrl, locations, isFirst]);

  return (
    <>
      {useMemo(
        () => (
          <Map ref={mapRef} id='map' isStatic={isStatic}></Map>
        ),
        [mapRef]
      )}
    </>
  );
};

const Map = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #eee;
  max-width: 100%;
  z-index: 3000;
  position: ${({ isStatic }) => (isStatic ? 'fixed !important' : 'relative')};
  overflow: hidden !important;
`;
