import React from "react";
import "./notFound.css";
import { useHistory } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";

const NotFound = ({ isWebView }) => {
  const { i18n } = useTranslation();
  const history = useHistory();

  return isWebView ? (
    <section className="pageErrorWebView">
      <p className="errorTitleWebview">식생지수를 불러오는데 실패했습니다</p>
      <p className="errorTitleWebview">
        아래의 버튼을 눌러 <span className="errorTitleColor">새로고침</span>{" "}
        해주세요
      </p>
      <button
        className="WebViewBtn"
        onClick={() => {
          history.go(0);
        }}
      >
        새로고침
      </button>
    </section>
  ) : (
    <section className="pageError">
      <h2 className="errorTitle">404</h2>
      <p className="errorDescription">
        <strong>{i18n.t("error.errorDescription1")}</strong>
        <br />
        {i18n.t("error.errorDescription2")}
      </p>
      <button
        className="prevBtn errorBtn"
        onClick={() => {
          history.goBack();
        }}
      >
        {i18n.t("error.prevPage")}
      </button>
      <button
        className="homeBtn errorBtn"
        onClick={() => {
          history.push("/");
        }}
      >
        {i18n.t("error.goGome")}
      </button>
    </section>
  );
};

export default withTranslation()(NotFound);
