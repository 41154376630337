import React, { useState, useEffect } from "react";

const TermService = () => {
  
  const [isRendering, setisRendering] = useState(false);

  useEffect(() => {
    setTimeout(() => setisRendering(true), 50);
  }, []);

  return ( isRendering && 
    <div className="policyWrapper">
      <div style={{ marginTop: 8 }}>
        <h2>이용약관</h2>
      </div>
      <div style={{ marginTop: 15 }}>
        <h3>제1장 총칙</h3>
      </div>
      <div style={{ marginBottom: 8, marginTop: 15 }}>
        <h3>제1조 (목적 및 정의)</h3>
      </div>
      <p>
        이 약관은 주식회사 에이아이에스(이하 “회사”라 합니다)가 크롬 브라우저 등
        웹 브라우져 및 모바일 기기 등(이하 “모바일 기기”라 하며, 아래에서 별도로
        정의합니다)을 통해 제공하는 농작물 재배 관리 서비스 “잘키움” 및 이에
        부수하는 애플리케이션, 네트워크, 웹사이트 등 서비스(이하, 통칭하여
        “서비스”라 합니다)의 이용에 대한 회사와 서비스 이용자의 권리ㆍ의무 및
        책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
      </p>
      <div style={{ marginBottom: 8, marginTop: 15 }}>
        <h3>제2조 (용어의 정의)</h3>
      </div>
      <ul>
        <li>① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</li>
        <ul>
          <li>
            1. “회사”라 함은 모바일 기기를 통하여 서비스를 제공하는 사업자를
            의미합니다.
          </li>
          <li>
            2. “회원”이란 이 약관에 따라 이용계약을 체결하고, 회사가 제공하는
            서비스를 이용하는 자를 의미합니다.
          </li>
          <li>
            3. “이용자”란 회원 및 회원으로서 이 약관에 따라 이용계약을 체결하기
            전 회사가 제공하는 서비스(가입 절차를 포함합니다)를 이용하는 자를
            의미합니다.
          </li>
          <li>
            4. “모바일 기기”란 서비스를 다운로드 받거나 설치하여 사용할 수 있는
            기기로서, 노트북, 휴대폰, 스마트폰, 휴대정보단말기(PDA), 태블릿,
            각종 디지털기기 등을 의미합니다.
          </li>
          <li>
            5. “계정정보”란 회원의 회원번호와 외부계정정보, 기기정보, 재배경력,
            작기 정보 등 농장운영 정보, 이메일주소, 이름, 전화번호, 농장주소,
            이용요금 결제 정보, 온실내 설치기기 정보 등 회원이 회사에 제공한
            정보와, 이용요금 결제 정보 등을 통칭합니다.
          </li>
          <li>
            6. “오픈마켓”이란 모바일 기기에서 서비스를 설치하고 결제할 수 있도록
            구축된 전자상거래 환경을 의미합니다.
          </li>
          <li>
            7. “애플리케이션 등”이란 회사가 제공하는 서비스를 이용하기 위하여
            모바일 기기를 통해 다운로드 받거나 설치하여 사용하는 프로그램 및
            모바일 기기를 통해 접속할 수 있는 네트워크, 웹사이트, 클라이언트
            프로그램 등 일체를 의미합니다.
          </li>
          <li>
            8. “프로그램” 이란 회사가 제공하는 서비스 내에서 제공되는 개별적인
            콘텐츠를 의미하며, 프로그램 별로 정해진 바에 따라 유상 또는
            무상으로, 일정 기간동안 또는 무기한으로 제공됩니다.
          </li>
        </ul>
        <li>
          ② 이 약관에서 사용하는 용어의 정의는 본 조 제1항에서 정하는 것을
          제외하고는 관계법령 및 서비스별 정책에서 정하는 바에 의하며, 이에
          정하지 아니한 것은 일반적인 상관례에 따릅니다.
        </li>
      </ul>
      <div style={{ marginTop: 15 }}>
        <h3>제3조 (회사정보 등의 제공)</h3>
      </div>
      <p>
        회사는 다음 각 호의 사항을 회원이 알아보기 쉽도록 애플리케이션 등 내에
        표시합니다. 다만, 개인정보처리방침과 약관은 회원이 연결화면을 통하여 볼
        수 있도록 할 수 있습니다.
      </p>
      <ul>
        <li>1. 상호 및 대표자의 성명</li>
        <li>
          2. 영업소 소재지 주소(회원의 불만을 처리할 수 있는 곳의 주소를
          포함한다)
        </li>
        <li>3. 전화번호, 전자우편주소</li>
        <li>4. 사업자 등록번호</li>
        <li>5. 통신판매업 신고번호</li>
        <li>6. 개인정보처리방침</li>
        <li>7. 서비스 이용약관</li>
      </ul>
      <div style={{ marginTop: 15 }}>
        <h3>제4조 (약관의 효력 및 변경)</h3>
      </div>
      <ul>
        <li>
          ① 회사는 이 약관의 내용을 회원이 알 수 있도록 서비스 내 또는 그
          연결화면에 게시합니다.
        </li>
        <li>
          ② 회사는 이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여
          현행약관과 함께 서비스 내 또는 그 연결화면에 그 적용일자 7일 이전부터
          적용일자 전일까지 공지 또는 통지합니다. 다만, 회원에게 불리하게
          약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고
          공지 또는 통지합니다.
        </li>
        <li>
          ③ 회사가 전항에 따라 개정약관을 공지 또는 통지하면서, 약관 변경
          적용일까지 거부의사를 표시하지 아니할 경우 약관의 변경에 동의한 것으로
          간주한다는 내용을 회원에게 명확하게 공지 또는 통지하였음에도 회원이
          명시적으로 거부의 의사표시를 하지 않은 경우, 회원이 개정약관에 동의한
          것으로 간주합니다.
        </li>
        <li>
          ④ 회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정약관의 내용을
          적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다. 다만,
          회사가 개정약관에 부동의한 회원에게 기존 약관을 적용할 수 없는 경우,
          회사는 해당 회원과의 이용계약을 해지할 수 있으며, 이 경우 로그인 후
          제공되는 서비스를 이용할 수 없습니다.
        </li>
        <li>
          ⑤ 회사는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의
          규제에 관한 법률」, 「정보통신망이용촉진 및 정보보호 등에 관한 법률」,
          「콘텐츠산업진흥법」 등 관련 법령에 위배하지 않는 범위에서 이 약관을
          개정할 수 있습니다.
        </li>
        <li>
          ⑥ 회사는 필요한 경우 특정 서비스에 관하여 적용될 사항을 위해
          개별약관이나 이용정책을 정하여 운영할 수 있으며, 해당 내용은 모바일
          기기를 통해 공지할 수 있습니다. 이 약관에 규정되지 않은 사항에
          대해서는 관련법령 또는 개별약관, 이용정책에서 정한 바에 따릅니다.
        </li>
      </ul>
      <div style={{ marginTop: 15 }}>
        <h3>제5조 (이용계약의 체결 및 적용)</h3>
      </div>
      <ul>
        <li>
          ① 이용계약은 회원이 되고자 하는 자(이하 “가입신청자”라 합니다)가 이
          약관의 내용에 대하여 동의를 한 다음 서비스 이용 신청을 하고, 회사가 그
          신청에 대해서 승낙함으로써 체결됩니다.
        </li>
        <li>
          ② 이용자가 만 14세미만인 경우에는 법정대리인의 동의를 받아 이용 신청을
          할 수 있으며 동의서를 첨부하여 이용 신청을 하여야 합니다.
        </li>
        <li>
          ③ 전항의 경우 만 14세미만 이용자의 부모 등 법정대리인은 아동에 대한
          개인정보의 열람, 정정, 갱신을 요청하거나 회원가입에 대한 동의를 철회할
          수 있으며, 이 경우 회사는 지체 없이 필요한 조치를 취해야 합니다.
        </li>
        <li>
          ④ 회사는 가입신청자의 신청에 대하여 승낙함을 원칙으로 합니다. 다만,
          회사는 다음 각 호의 어느 하나에 해당하는 이용 신청에 대해서는 승낙을
          거절할 수 있습니다.
        </li>
        <ul>
          <li>
            1. 이용신청서 내용을 허위로 기재하거나 필수 제공사항인 정보를
            누락하거나 오기하여 신청하는 경우 또는 이용신청 요건을 충족하지 못한
            경우
          </li>
          <li>
            2. 회사가 서비스를 제공하지 않은 국가에서 비정상적이거나 우회적인
            방법을 통해 서비스를 이용하는 경우
          </li>
          <li>
            3. 「콘텐츠산업진흥법」등 관련 법령에서 금지하는 행위를 할 목적으로
            신청하는 경우
          </li>
          <li>
            4. 사회의 안녕과 질서 또는 미풍양속을 저해할 목적으로 신청한 경우
          </li>
          <li>
            5. 부정한 용도로 서비스를 이용하고자 하는 경우(본 서비스와
            경쟁관계가 있는 이용자가 신청하는 경우 포함)
          </li>
          <li>6. 영리를 추구할 목적으로 서비스를 이용하고자 하는 경우</li>
          <li>
            7. 가입신청자가 회원탈퇴 또는 신청자의 귀책사유로 인한 회사의 계약
            해지로 회원 자격을 상실한 사실이 있는 경우
          </li>
          <li>
            8. 회사로부터 서비스 이용 정지 조치 등을 받은 자가 그 조치기간 중에
            이용계약을 임의 해지하고 재이용신청을 하는 경우
          </li>
          <li>9. 실명이 아니거나 타인의 명의를 이용하는 경우</li>
          <li>
            10. 그 밖에 각 호에 준하는 사유로서 승낙이 부적절하다고 판단되는
            경우
          </li>
        </ul>
        <li>
          ⑤ 회사는 다음 각 호의 어느 하나에 해당하는 경우 그 사유가 해소될
          때까지 승낙을 유보할 수 있으며 이를 신청자에게 알려야 합니다. 다만
          회사의 귀책사유 없이 신청자에게 통지할 수 없는 경에는 예외로 합니다.
        </li>
        <ul>
          <li>
            1. 회사의 설비에 여유가 없거나, 특정 모바일 기기의 지원이 어렵거나,
            기술적 장애가 있는 경우
          </li>
          <li>
            2. 서비스 상의 장애 또는 서비스 이용요금, 결제수단의 장애가 발생한
            경우
          </li>
          <li>
            3. 그 밖의 각 호에 준하는 사유로서 이용신청의 승낙이 어렵다고
            판단되는 경우
          </li>
        </ul>
      </ul>
      <div style={{ marginTop: 15 }}>
        <h3>제6조 (회원정보의 변경)</h3>
      </div>
      <ul>
        <li>
          ① 회원은 개인정보관리화면을 통하여 언제든지 자신의 개인정보를 열람하고
          수정할 수 있습니다.
        </li>
        <li>
          ② 회원은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로
          수정을 하거나 전자우편 기타 방법으로 회사에게 그 변경사항을 알려야
          합니다.
        </li>
        <li>
          ③ 제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는
          책임지지 않습니다.
        </li>
      </ul>

      <div style={{ marginTop: 15 }}>
        <h3>제7조 (약관 외 준칙)</h3>
      </div>
      <p>
        이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 「전자상거래
        등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」,
        「정보통신망이용촉진 및 정보보호 등에 관한 법률」, 「콘텐츠산업진흥법」
        등 관련 법령 또는 상관례에 따릅니다.
      </p>
      <div style={{ marginTop: 15 }}>
        <h3>제8조 (운영정책)</h3>
      </div>
      <ul>
        <li>
          ① 약관을 적용하기 위하여 필요한 사항과 약관에서 구체적 범위를 정하여
          위임한 사항을 서비스 운영정책(이하 “운영정책”이라 합니다)으로 정할 수
          있습니다.
        </li>
        <li>
          ② 회사는 운영정책의 내용을 회원이 알 수 있도록 서비스 내 또는 그
          연결화면에 게시합니다.
        </li>
        <li>
          ③ 운영정책을 개정하는 경우에는 제4조 제2항의 절차에 따릅니다. 다만,
          운영정책 개정내용이 다음 각 호의 어느 하나에 해당하는 경우에는 제2항의
          방법으로 사전에 공지합니다.
        </li>
        <ul>
          <li>
            1. 약관에서 구체적으로 범위를 정하여 위임한 사항을 개정하는 경우
          </li>
          <li>2. 회원의 권리·의무와 관련 없는 사항을 개정하는 경우</li>
          <li>
            3. 운영정책의 내용이 약관에서 정한 내용과 근본적으로 다르지 않고
            회원이 예측할 수 있는 범위 내에서 운영정책을 개정하는 경우
          </li>
        </ul>
      </ul>
      <div style={{ marginTop: 15 }}>
        <h3>제2장 개인정보 관리</h3>
      </div>
      <div style={{ marginTop: 15 }}>
        <h3>제9조 (개인정보의 보호 및 사용)</h3>
      </div>
      <ul>
        <li>
          ① 회사는 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해
          노력하며, 개인정보의 보호 및 사용에 대해서는 관련 법령 및 회사의
          개인정보처리방침에 따릅니다. 다만, 회사가 제공하는 서비스 이외의
          링크된 서비스에서는 회사의 개인정보처리방침이 적용되지 않습니다.
        </li>
        <li>
          ② 회사는 관련 법령에 의해 관련 국가기관 등의 요청이 있는 경우를
          제외하고는 회원의 개인정보를 본인의 동의 없이 타인에게 제공하지
          않습니다.
        </li>
        <li>
          ③ 회사는 회원의 귀책사유로 개인정보가 유출되어 발생한 피해에 대하여
          책임을 지지 않습니다.
        </li>
      </ul>
      <div style={{ marginTop: 15 }}>
        <h3>제3장 이용계약 당사자의 의무</h3>
      </div>
      <div style={{ marginTop: 15 }}>
        <h3>제10조 (회사의 의무)</h3>
      </div>
      <ul>
        <li>
          ① 회사는 관련 법령, 이 약관에서 정하는 권리의 행사 및 의무의 이행을
          신의에 따라 성실하게 준수합니다.
        </li>
        <li>
          ② 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보
          포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을
          공시하고 준수합니다. 회사는 이 약관 및 개인정보처리방침에서 정한
          경우를 제외하고는 회원의 개인정보가 제3자에게 공개 또는 제공되지
          않도록 합니다.
        </li>
        <li>
          ③ 회사는 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선을 하던
          중 설비에 장애가 생기거나 데이터 등이 멸실․훼손된 때에는 천재지변,
          비상사태, 현재의 기술로는 해결이 불가능한 장애나 결함 등 부득이한
          사유가 없는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을
          다합니다.
        </li>
      </ul>
      <div style={{ marginTop: 15 }}>
        <h3>제11조 (회원의 의무)</h3>
      </div>
      <ul>
        <li>
          ① 이용자는 회원가입을 통해 이용신청을 할 경우 사실에 근거하여
          작성하여야 합니다. 이용자가 허위, 또는 타인의 정보를 등록한 경우
          회사에 대하여 일체의 권리를 주장할 수 없으며, 회사는 이로 인하여
          발생하는 손해에 대하여 책임을 부담하지 않습니다.
        </li>
        <li>
          ② 회원은 회사에서 제공하는 서비스의 이용과 관련하여 다음 각 호에
          해당하는 행위를 해서는 안 됩니다. 아래 각 호에 해당되는 행위를 할
          경우에는 이 약관 및 각 서비스 별로 공지하는 운영정책에 따라 회원의
          서비스 이용을 제한하거나 계정의 삭제, 수사기관의 고발 조치 등 적법한
          조치를 포함한 제재를 가할 수 있습니다.
        </li>
        <ul>
          <li>
            1. 이용신청 또는 회원 정보 변경 시 허위사실을 기재하거나, 타인의
            명의로 본인인증을 수행하거나, 다른 회원의 계정 또는 타인의
            결제수단을 도용, 부정하게 사용하는 행위
          </li>
          <li>
            2. 회사가 제공하지 않는 서비스나 비정상적인 방법을 통해 사이버
            자산(ID 등)을 매매 또는 증여, 대여하거나, 이를 취득하여 이용하는
            행위
          </li>
          <li>
            3. 회사의 직원이나 운영자를 가장하거나 타인의 명의를 도용하여 글을
            게시하거나 메일을 발송하는 행위, 타인으로 가장하거나 타인과의 관계를
            허위로 명시하는 행위거나 다른 이용자에게 혼란을 초래하는 행위
          </li>
          <li>
            4. 타인의 신용카드⋅유/무선 전화⋅은행 계좌 등을 도용하여 유료
            프로그램을 구매(다른 회원에 대한 후원을 포함하며 이하 동일함)하는
            행위, 다른 회원의 ID 및 비밀번호를 부정사용하는 행위, 결제기관을
            속여 부정한 방법으로 결제하거나 지불거절(Chargebeck)을 악용하여
            정당한 이유 없이 유료서비스를 구매하거나 환불하는 경우
          </li>
          <li>
            5. 다른 회원의 개인정보를 무단으로 수집⋅저장⋅게시 또는 유포하는 행위
          </li>
          <li>
            6. 도박 등 사행행위를 하거나 유도하는 행위, 음란⋅저속한 정보를
            교류⋅게재하거나 음란 사이트를 연결(링크)하는 행위, 수치심⋅혐오감
            또는 공포심을 일으키는 말⋅소리⋅글⋅그림⋅사진 또는 영상을 타인에게
            전송 또는 유포하는 행위 등 서비스를 불건전하게 이용하는 행위
          </li>
          <li>
            7. 서비스를 무단 또는 사전 서면 동의 없이 영리, 영업, 광고, 홍보,
            정치활동, 선거운동 등 본래의 용도 이외의 용도로 이용하는 행위
          </li>
          <li>
            8. 회사의 서비스를 이용하여 얻은 정보를 무단으로
            복제․유통․조장하거나 상업적으로 이용하는 행위, 알려지거나 알려지지
            않은 버그를 악용하여 서비스를 이용하는 행위
          </li>
          <li>
            9. 타인을 기망하여 이득을 취하는 행위, 회사의 서비스의 이용과
            관련하여 타인에게 피해를 입히는 행위
          </li>
          <li>
            10. 회사나 타인의 지적재산권, 초상권 등 기타 권리를 침해하는 행위,
            타인의 명예를 훼손하거나 손해를 가하는 행위, 타인을 희롱 또는
            위협하거나 고통, 피해, 또는 불편을 주는 행위
          </li>
          <li>
            11. 법령에 의하여 전송 또는 게시가 금지된 정보(컴퓨터 프로그램)나
            컴퓨터 소프트웨어⋅하드웨어 또는 전기통신장비의 정상적인 작동을
            방해⋅파괴할 목적으로 고안된 바이러스⋅컴퓨터 코드⋅파일⋅프로그램 등을
            고의로 전송⋅게시⋅유포 또는 사용하는 행위, 회사가 제공하는 서비스
            프로그램의 이용방식, 기획의도를 변경하거나 서비스에 비정상적으로
            위해를 가하거나 고의로 방해하는 일체의 행위
          </li>
          <li>
            12. 회사로부터 특별한 권리를 부여 받지 않고 애플리케이션 등을
            변경하거나, 애플리케이션 등에 다른 프로그램을 추가⋅삽입하거나,
            서버를 해킹⋅역설계하거나, 소스 코드나 애플리케이션 등 데이터를
            유출⋅변경하거나, 별도의 서버를 구축하거나, 웹사이트의 일부분을
            임의로 변경⋅도용하여 회사를 사칭하는 행위
          </li>
          <li>
            13. 자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 가할
            목적으로 허위의 정보를 유통시키는 행위
          </li>
          <li>
            14. 회원 탈퇴, 재가입 등을 반복적으로 행하거나, 회사가 정하지 않은
            비정상적인 방식으로 쿠폰, 이벤트 혜택 등의 경제상의 이익을 수취하는
            행위
          </li>
          <li>
            15. 부정한 목적으로 다량의 정보를 전송하여 서비스의 안정적인 운영을
            방해하는 행위
          </li>
          <li>
            16. 회사 또는 다른 이용자의 게시물, 데이터 등을 전송을 방해하거나
            훼손, 삭제하는 행위
          </li>
          <li>
            17. 회사의 허가 없이 소프트웨어 또는 하드웨어를 이용하여 서비스의
            내용에 권한 없이 관여하는 소프트웨어 혹은 하드웨어를 사용,
            배포하거나 사용을 장려하거나 광고를 하는 행위
          </li>
          <li>
            18. 본 서비스를 통해 얻은 정보를 회사의 사전 승낙 없이 서비스 이용
            외의 목적으로 복제하거나, 이를 출판 및 방송 등에 사용하거나,
            제3자에게 제공하는 행위
          </li>
          <li>
            19. 회사의 서비스 이용대금, 기타 회사의 서비스 이용에 관련하여
            회원이 부담하는 채무를 기일에 이행하지 않는 경우
          </li>
          <li>
            20. 이 약관을 포함하여 기타 회사가 정한 제반 규정 또는 이용 조건을
            위반 하는 행위 및 기타 관계 법령에 위배되는 행위, 그 밖의 선량한
            풍속 기타 사회통념에 반하는 행위
          </li>
        </ul>
        <li>
          ② 회원의 계정 및 모바일 기기에 관한 관리 책임은 회원에게 있으며, 이를
          타인이 이용하도록 하게 하여서는 안 됩니다. 모바일 기기의 관리 부실이나
          타인에게 이용을 승낙함으로 인해 발생하는 손해에 대해서 회사는 책임을
          지지 않습니다.
        </li>
        <li>
          ③ 회원은 각 오픈마켓에서 부정한 결제가 이루어지지 아니하도록 결제
          비밀번호 기능을 설정하고 관리하여야 합니다. 회원의 부주의로 인하여
          발생하는 손해에 대해 회사는 책임지지 않습니다
        </li>
        <li>
          ④ 회사는 다음 각 호의 행위를 포함하여 회사의 서비스와 관련하여
          구체적인 내용을 정할 수 있으며, 회원은 이를 따라야 합니다.
        </li>
        <ul>
          <li>1. 회원의 계정명, 기타 서비스 내에서 사용하는 명칭</li>
          <li>2. 농장 관련 데이터</li>
          <li>3. 농장 환경 분석 리포트 제공</li>
          <li>4. 컨설팅</li>
          <li>5. 이외에 서비스를 통해 제공되는 프로그램의 내용 등</li>
        </ul>
      </ul>
      <div style={{ marginTop: 15 }}>
        <h3>제4장 서비스 이용 및 이용제한</h3>
      </div>
      <div style={{ marginTop: 15 }}>
        <h3>제12조 (서비스의 제공 및 제한)</h3>
      </div>
      <ul>
        <li>① 회사가 제공하는 서비스 내 프로그램의 내용은 다음과 같습니다.</li>
        <ul>
          <li>1. 회사의 시스템에서 수집된 데이터의 실시간 모니터링</li>
          <li>2. 회사의 시스템에서 수집된 데이터에 대한 시각화</li>
          <li>3. 회사의 시스템에서 수집된 데이터의 보관 및 분석</li>
          <li>4. 농장, 재배 등 컨설팅</li>
          <li>5. 수집된 데이터를 기반으로 한 작물생육알고리즘 재배 처방</li>
          <li>
            6. 기타 이 약관 및 회사가 정하는 바에 따라 회원에게 제공하는
            프로그램
          </li>
        </ul>
        <li>
          ② 회사는 제5조의 규정에 따라 이용계약이 완료된 회원에게 그 즉시
          서비스를 이용할 수 있도록 합니다. 다만, 일부 서비스의 경우 회사의
          필요에 따라 지정된 일자부터 서비스를 개시할 수 있습니다.
        </li>
        <li>
          ③ 회사는 회원에게 서비스를 제공할 때 이 약관에 정하고 있는 서비스를
          포함하여 기타 부가적인 서비스를 함께 제공할 수 있습니다.
        </li>
        <li>
          ④ 회사는 회원의 등급을 구분하고 이용시간, 이용횟수, 제공 서비스의 범위
          등을 세분화하여 이용에 차등을 둘 수 있습니다.
        </li>
      </ul>
      <div style={{ marginTop: 15 }}>
        <h3>제13조 (서비스의 이용)</h3>
      </div>
      <ul>
        <li>
          ① 서비스는 회사의 영업방침에 따라 정해진 시간 동안 제공합니다. 회사는
          서비스 제공시간을 애플리케이션 등의 초기화면이나 서비스 공지사항에
          적절한 방법으로 안내합니다.
        </li>
        <li>
          ② 제1항에도 불구하고 회사는 다음 각 호의 경우에는 서비스의 전부 또는
          일부를 일시 정지할 수 있습니다. 이 경우 회사는 사전에 그 정지의 사유와
          기간을 애플리케이션 등의 초기화면이나 서비스 공지사항 등에 공지합니다.
          다만, 사전에 공지할 수 없는 부득이한 사정이 있는 경우 사후에 공지할 수
          있습니다.
        </li>
        <ul>
          <li>
            1. 시스템 정기점검, 서버의 증설 및 교체, 네트워크의 불안정 등의
            시스템 운영상 필요한 경우
          </li>
          <li>
            2. 정전, 서비스 설비의 장애, 서비스 이용폭주, 기간통신사업자의 설비
            보수 또는 점검 등으로 인하여 정상적인 서비스 제공이 불가능한 경우
          </li>
          <li>
            3. 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태 등 회사가
            통제할 수 없는 상황이 발생한 경우
          </li>
        </ul>
        <li>
          ③ 회사는 모바일 기기를 위한 애플리케이션 등을 이용하여 서비스를
          제공합니다. 회원은 애플리케이션 등을 다운로드하여 설치하거나
          네트워크를 이용하여 무료 또는 유료로 프로그램을 이용할 수 있습니다.
        </li>
        <li>
          ④ 유료 프로그램의 경우에는 해당 서비스에 명시된 요금을 지급하여야
          이용할 수 있습니다. 애플리케이션 등을 다운로드하거나 서비스를 이용하는
          경우에는 가입한 이동통신사에서 정한 별도의 요금이 발생할 수 있습니다.
        </li>
        <li>
          ⑤ 애플리케이션 등을 이용하는 서비스의 경우에는 모바일 기기 또는
          이동통신사의 특성에 맞도록 제공됩니다. 모바일 기기의 변경․번호 변경
          또는 해외 로밍의 경우에는 프로그램의 전부 또는 일부의 이용이 불가능할
          수 있으며, 이 경우 회사는 책임을 지지 않습니다.
        </li>
        <li>
          ⑥ 애플리케이션 등을 통해 이용하는 서비스의 경우에는 백그라운드 작업이
          진행될 수 있습니다. 이 경우 모바일 기기 또는 이동통신사의 특성에
          맞도록 추가요금이 발생할 수 있으며 이와 관련하여 회사는 책임을 지지
          않습니다.
        </li>
      </ul>
      <div style={{ marginTop: 15 }}>
        <h3>제14조 (서비스의 변경 및 중단)</h3>
      </div>
      <ul>
        <li>
          ① 회사는 원활한 서비스 제공을 위해 운영상 또는 기술상의 필요에 따라
          서비스를 변경할 수 있으며, 변경 전에 해당 내용을 서비스 내에
          공지합니다. 다만, 버그․오류 등의 수정이나 긴급 업데이트 등 부득이하게
          변경할 필요가 있는 경우 또는 중대한 변경에 해당하지 않는 경우에는
          사후에 공지할 수 있습니다.
        </li>
        <li>
          ② 회사는 영업양도․분할․합병 등에 따른 영업의 폐지, 서비스제공의
          계약만료, 당해 서비스의 현저한 수익 악화 등 경영상의 중대한 사유로
          인해 서비스를 지속하기 어려운 경우에는 서비스 전부를 중단할 수
          있습니다. 이 경우 중단일자 30일 이전까지 중단일자․중단사유․보상조건
          등을 애플리케이션 등 초기화면 또는 그 연결화면을 통해 공지하고 제27조
          제1항의 방법으로 회원에게 통지합니다.
        </li>
        <li>
          ③ 제2항의 경우 회사는 사용하지 않았거나 사용기간이 남아 있는 유료
          프로그램에 대해 제22조에 따라 환급합니다.
        </li>
      </ul>
      <div style={{ marginTop: 15 }}>
        <h3>제15조 (정보의 수집 등)</h3>
      </div>
      <ul>
        <li>
          ① 회사는 서비스 제공을 위하여 담당자 전화번호 등 회원이 회사에게
          제공하거나 혹은 회사가 회원이 이용하는 서비스를 통해 수집하는
          데이터(농가의 환경, 농장정보, 농장주소, 재배면적, 재배작물, 작기정보를
          포함한 재배정보, 경경 잎면적 등을 포함한 작물 생육 정보, 온도, 습도,
          풍향, 풍속, 일사량, 강우량, 토양 센서 데이터 등을 포함하나
          개인정보보호법에 따라 보호받는 개인정보는 제외함, 이하 “회원 제공
          데이터”)를 회사 클라우드 서버에 전송, 저장하고 이용할 수 있습니다.
        </li>
        <li>
          ② 회원 제공 데이터의 소유권은 회원에게 있습니다. 그러나 회원은 이
          약관에 동의함으로써 회사가 다음과 같이 회원 제공 데이터를 이용하는
          것을 허락합니다.
        </li>
        <ul>
          <li>1. 회원 제공 데이터의 분석 및 통계 작성</li>
          <li>
            2. 회원 제공 데이터의 복제, 수정, 각색, 공연, 전시, 방송, 배포,
            대여, 공중송신, 2차적 저작물 작성, 기타 이용
          </li>
          <li>
            3. 회원 제공 데이터를 회사의 기술 개발(기계 학습 등), 기타 서비스
            개선을 위한 목적으로 이용
          </li>
          <li>
            4. 회원 제공 데이터를 기술 개발 기타 서비스 개선을 위한 목적으로
            학술, 연구기관 업체에게 제공
          </li>
          <li>
            5. 이 약관 제15조 제3항에 따라 회사가 지정하는 제3자에 대한 회원
            제공 데이터의 제공
          </li>
        </ul>
        <li>
          ③ 위 제2항에 규정에 따라 회사가 회원 제공 데이터를 이용할 수 있는
          조건은 아래와 같습니다.
        </li>
        <ul>
          <li>
            1. 회원 제공 데이터 이용 매체/플랫폼 - 현재 알려져 있거나 앞으로
            개발된 회사가 운영하는 모든 매체, 장비, 기술을 포함함.
          </li>
          <li>
            2. 회원 제공 데이터 이용 용도 - 상업적 또는 비상업적 이용을 구분하지
            않음.
          </li>
          <li>
            3. 회원 제공 데이터 이용 범위 - 국내 및 국외에서의 복제, 수정, 각색,
            공연, 전시, 방송, 배포, 대여, 공중송신, 2차적 저작물 작성, 기타 이용
          </li>
          <li>
            4. 회원 제공 데이터 이용 조건 - 비독점적, 지속적인 무상의 권리
          </li>
        </ul>
        <li>
          ④ 이 조는 회사가 서비스를 운영하는 동안 유효하며, 회원 탈퇴 후에도
          지속적으로 적용됩니다.
        </li>
      </ul>
      <div style={{ marginTop: 15 }}>
        <h3>제16조 (저작권 등의 귀속)</h3>
      </div>
      <ul>
        <li>
          ① 회사가 제공하는 서비스, 그에 필요한 소프트웨어, 프로그램, 이미지,
          마크, 로고, 디자인, 서비스명칭, 정보 및 상표 등과 관련된 지적재산권 및
          기타 권리는 회사에 소유권 및 지적재산권이 있습니다.
        </li>
        <li>
          ② 회원은 회사가 제공하는 서비스를 이용하여 얻은 정보 중에서 회사 또는
          제공업체에 지적재산권이 귀속된 정보를 회사 또는 제공업체의 사전 동의
          없이 복제⋅전송 등의 방법(편집, 공표, 공연, 배포, 방송, 2차적 저작물
          작성 등을 포함합니다. 이하 같습니다)에 의하여 영리목적으로 이용하거나
          타인에게 이용하게 하여서는 안 됩니다.
        </li>
        <li>
          ③ 회원 제공 데이터에 대한 소유권 및 지적재산권은 회원에게 있습니다.
          그러나, 회원은 이 약관이 정하는 바에 따라 회사가 지정하는 제3자가 회원
          제공 데이터를 이용하는 것을 허락합니다.
        </li>
        <ul>
          <li>1. 회원 제공 데이터의 열람</li>
          <li>
            2. 회원 제공 데이터의 분석 및 통계작성, 기술 개발 기타 서비스 개선을
            위한 목적으로 학술, 연구기관 업체에게 제공하여 국내 및 국외에서의
            복제, 수정, 각색, 공연, 전시, 방송, 배포, 대여, 공중송신, 2차적
            저작물 작성, 기타 이용
          </li>
          <li>
            3. 학술, 연구, 그 외의 목적으로 회원 제공 데이터를 활용하여 보고서
            등의 작성 및 배포
          </li>
          <li>
            4. 기타 이 약관 또는 개별 프로그램에서 정하는 방법으로 이용할 수
            있도록 함.
          </li>
        </ul>
        <li>
          ④ 위 제4항에 규정에 따라 회사가 지정하는 제3자가 회원 제공 데이터를
          이용할 수 있는 범위에 대해서는, 회원이 설정한 바에 따릅니다.
        </li>
        <li>
          ⑤ 이 조는 회사가 서비스를 운영하는 동안 유효하며, 회원 탈퇴 후에도
          지속적으로 적용됩니다.
        </li>
      </ul>
      <div style={{ marginTop: 15 }}>
        <h3>제17조 (광고의 제공)</h3>
      </div>
      <ul>
        <li>
          ① 회사는 서비스의 운영과 관련하여 서비스 내에 광고를 게재할 수
          있습니다. 또한 수신에 동의한 회원에 한하여 전자우편,
          문자서비스(LMS/SMS), 푸시메시지(Push Notification) 등의 방법으로
          광고성 정보를 전송할 수 있습니다. 이 경우 회원은 언제든지 수신을
          거절할 수 있으며, 회사는 회원의 수신 거절 시 광고성 정보를 발송하지
          아니합니다.
        </li>
        <li>
          ② 회사가 제공하는 서비스 중의 배너 또는 링크 등을 통해 타인이 제공하는
          광고나 서비스에 연결될 수 있습니다.
        </li>
        <li>
          ③ 제2항에 따라 타인이 제공하는 광고나 서비스에 연결될 경우 해당
          영역에서 제공하는 서비스는 회사의 서비스 영역이 아니므로 회사가
          신뢰성, 안정성 등을 보장하지 않으며, 그로 인한 회원의 손해에 대하여도
          회사는 책임을 지지 않습니다. 다만, 회사가 고의 또는 중과실로 손해의
          발생을 용이하게 하거나 손해 방지를 위한 조치를 취하지 아니한 경우에는
          그러하지 아니합니다.
        </li>
      </ul>
      <div style={{ marginTop: 15 }}>
        <h3>제18조 (유료 프로그램의 구매, 사용기간 및 이용)</h3>
      </div>
      <ul>
        <li>
          ① 서비스 내에서 회원이 구매한 유료 프로그램은 모바일 기기에서만 이용할
          수 있습니다.
        </li>
        <li>
          ② 회원이 구매한 유료 프로그램의 이용기간은 구매 시 명시된 기간에
          따릅니다. 다만, 제14조 제2항에 따라 서비스 중단이 이루어지는 경우
          기간의 정함이 없는 유료 프로그램의 이용기간은 서비스 중단 공지 시
          공지된 서비스의 중단일자까지로 합니다.
        </li>
      </ul>
      <div style={{ marginTop: 15 }}>
        <h3>제19조 (회원에 대한 서비스 이용제한)</h3>
      </div>
      <ul>
        <li>
          ① 회원은 제10조에 따른 회원의 의무를 위반하는 행위를 하여서는 안 되며,
          해당 행위를 하는 경우에 회사는 다음 각 호의 구분에 따른 회원의 서비스
          이용제한 및 기타의 조치를 포함한 이용제한 조치를 할 수 있습니다.
          이용제한 조치가 이루어지는 구체적인 사유 및 절차는 제19조 제1항에 따라
          개별 서비스의 운영정책에서 정합니다.
        </li>
        <ul>
          <li>1. 일부 권한 제한 : 일정기간 서비스 내 일정 권한을 제한</li>
          <li>
            2. 계정 이용제한 : 일정기간 또는 영구히 회원 계정의 이용을 제한
          </li>
          <li>
            3. 회원 이용제한 : 일정기간 또는 영구히 회원의 서비스 이용을 제한
          </li>
        </ul>
        <li>
          ② 제1항의 이용제한이 정당한 경우에 회사는 이용제한으로 인하여 회원이
          입은 손해를 배상하지 않습니다.
        </li>
        <li>
          ③ 회사는 다음 각 호의 사유에 대한 조사가 완료될 때까지 해당 계정의
          서비스 이용을 정지할 수 있습니다.
        </li>
        <ul>
          <li>1. 계정이 해킹 또는 도용 당했다는 정당한 신고가 접수된 경우</li>
          <li>2. 불법프로그램 사용자 등 위법행위자로 의심되는 경우</li>
          <li>
            3. 이 계약 제10조의 위반자로 의심되거나 다른 회원의 신고가 접수된
            경우
          </li>
          <li>
            4. 그 밖에 각 호에 준하는 사유로 서비스 이용의 잠정조치가 필요한
            경우
          </li>
        </ul>
        <li>
          ④ 제3항의 조사가 완료된 후, 유료 프로그램의 경우에는 정지된 시간만큼
          회원의 이용시간을 연장하거나 그에 상당하는 유료 프로그램 또는 캐쉬
          등으로 보상합니다. 다만, 회원이 제3항 각 호의 사유에 해당하는 경우에는
          그러하지 아니합니다.
        </li>
      </ul>
      <div style={{ marginTop: 15 }}>
        <h3>제20조 (이용제한 조치의 사유와 절차)</h3>
      </div>
      <ul>
        <li>
          ① 회사는 제18조 제1항에 따른 이용제한 조치의 구체적인 사유 및 절차를
          제10조 제2항에 따른 금지행위의 내용,정도,횟수,결과 등을 고려하여
          운영정책으로 정합니다.
        </li>
        <li>
          ② 회사가 제18조 제1항에서 정한 이용제한 조치를 하는 경우에는 다음 각
          호의 사항을 회원에게 사전 통지합니다. 다만, 긴급히 조치할 필요가 있는
          경우에는 사후에 통지할 수 있습니다.
        </li>
        <ul>
          <li>1. 이용제한 조치의 사유</li>
          <li>2. 이용제한 조치의 유형 및 기간</li>
          <li>3. 이용제한 조치에 대한 이의신청 방법</li>
        </ul>
      </ul>
      <div style={{ marginTop: 15 }}>
        <h3>제21조 (이용제한 조치에 대한 이의신청 절차)</h3>
      </div>
      <ul>
        <li>
          ① 회원이 회사의 이용제한 조치에 불복하고자 할 때에는 이 조치의 통지를
          받은 날부터 14일 이내에 불복 이유를 기재한 이의 신청서를 서면,
          전자우편 또는 이에 준하는 방법으로 회사에 제출하여야 합니다.
        </li>
        <li>
          ② 회사는 제1항의 이의신청서를 접수한 날부터 15일 이내에 불복 이유에
          대하여 서면, 전자우편 또는 이에 준하는 방법으로 답변합니다. 다만,
          회사는 이 기간 내에 답변이 어려운 경우에는 그 사유와 처리일정을
          통지합니다.
        </li>
        <li>③ 회사는 불복 이유가 타당한 경우에는 이에 따른 조치를 취합니다.</li>
      </ul>
      <div style={{ marginTop: 15 }}>
        <h3>제5장 청약철회, 과오납금의 환급 및 이용계약의 해지</h3>
      </div>
      <div style={{ marginTop: 15 }}>
        <h3>제22조 (대금결제)</h3>
      </div>
      <ul>
        <li>
          ① 유료 프로그램에 대한 구매 대금의 부과와 납부는 원칙적으로
          이동통신사나 오픈마켓 사업자 등에서 정하는 정책이나 방법에 따릅니다.
          또한 각 결제수단별 한도가 회사나 오픈마켓 사업자가 정하는 정책 또는
          정부의 방침에 따라 부여되거나 조정될 수 있습니다.
        </li>
        <li>
          ② 유료 프로그램의 구매대금을 외화로 결제하는 경우에는 환율‧수수료
          등으로 인하여 실제 청구금액이 서비스의 상점 등에서 표시된 가격과
          달라질 수 있습니다.
        </li>
        <li>
          ③ 유료 프로그램의 구매대금 결제 방법은 핸드폰결제, 신용카드결제,
          일반전화결제, 계좌이체, 무통장입금, 선불전자지급수단 결제 등이 있으며
          회사가 제공하는 각 프로그램마다 결제 방법의 차이가 있을 수 있습니다.
          매월 정기적인 결제가 이루어지는 프로그램의 경우 회원 개인이 해당
          서비스의 이용을 중단하고 정기 결제의 취소를 요청하지 않는 한 매월
          결제가 이루어집니다.
        </li>
      </ul>
      <div style={{ marginTop: 15 }}>
        <h3>제23조 (청약 철회 및 환불 등)</h3>
      </div>
      <ul>
        <li>
          ① 회사와 유료 프로그램의 구매에 관한 계약을 체결한 회원은 구매계약일과
          프로그램 이용 가능일 중 늦은 날부터 7일 이내에 별도의 수수료‧위약금
          등의 부담 없이 청약철회를 할 수 있습니다.
        </li>
        <li>
          ② 회원은 다음 각 호에 해당하는 경우에는 회사의 의사에 반하여 제1항에
          따른 청약철회를 할 수 없습니다.
        </li>
        <ul>
          <li>1. 구매 즉시 사용되거나 적용되는 유료 프로그램</li>
          <li>2. 추가혜택이 제공되는 경우에 그 추가 혜택이 사용된 프로그램</li>
          <li>
            3. 개봉행위를 사용으로 볼 수 있거나 개봉 시 효용이 결정되는
            프로그램의 개봉행위가 있는 경우
          </li>
          <li>4. 회사가 청약의 철회가 불가능함을 표시한 프로그램</li>
        </ul>
        <li>
          ③ 회사는 제2항 각 호의 규정에 따라 청약철회가 불가능한 프로그램의
          경우에는 그 사실을 회원이 쉽게 알 수 있는 곳에 명확하게 표시하고, 해당
          프로그램의 시험사용 상품을 제공(한시적 이용의 허용, 체험용 제공
          등)하거나 이에 대한 제공이 곤란한 경우에는 프로그램에 관한 정보
          제공함으로써 회원의 청약철회의 권리행사가 방해받지 아니하도록
          조치합니다. 만약 회사가 이러한 조치를 하지 아니한 경우에는 제2항 각
          호의 청약철회 제한사유에도 불구하고 회원은 청약철회를 할 수 있습니다.
        </li>
        <li>
          ④ 회원은 제1항 및 제2항에도 불구하고 구매한 유료 프로그램의 내용이
          표시․광고의 내용과 다르거나 구매계약의 내용과 다르게 이행된 경우에
          해당 프로그램이 이용 가능하게 된 날부터 3개월 이내, 그 사실을 안 날
          또는 알 수 있었던 날부터 30일 이내에 청약철회를 할 수 있습니다.
        </li>
        <li>
          ⑤ 회원이 청약철회를 할 경우 회사는 플랫폼사업자 또는 오픈마켓 사업자를
          통해 구매내역을 확인합니다. 또한 회사는 회원의 정당한 철회 사유를
          확인하기 위해 회원에게서 제공받은 정보를 통해 회원에게 연락할 수
          있으며, 추가적인 증빙을 요구할 수 있습니다.
        </li>
        <li>
          ⑥ 제1항부터 제4항까지의 규정에 따라 청약철회가 이루어질 경우 회사는
          지체 없이 회원의 유료 프로그램을 회수하고 회원이 유료 프로그램의
          구매대금을 결제한 방법과 동일한 방법으로 대금을 환불하여야 하며,
          동일한 방법으로 환불이 불가한 경우 이를 사전에 회원에게 고지합니다. 이
          경우 최종 환불은 회원이 유료 프로그램의 구매 대금을 결제한 결제 수단에
          따라 일정이 달라질 수 있습니다.
        </li>
        <li>
          ⑦ 위 제6항에도 불구하고 회원이 앱마켓 결제(인앱결제)를 통해 유료
          프로그램의 구매대금을 결제한 경우, 회사는 앱마켓 결제(인앱결제)에
          대하여 대금지급의 정치, 취소 요청 등 「전자상거래등에서의 소비자보호에
          관한 법률」에 따른 관련 조치를 취합니다. 단, 앱마켓 결제(인앱결제)의
          환불은 해당 앱마켓에서 관리하며, 해당 앱마켓의 정책에 따라 환불이
          진행됩니다.
        </li>
        <li>
          ⑧ 미성년자가 모바일 기기에서 프로그램 구매계약을 체결하는 경우, 회사는
          법정대리인의 동의가 없으면 미성년자 본인 또는 법정대리인이 그 계약을
          취소할 수 있다는 내용을 고지하며, 미성년자가 법정대리인의 동의 없이
          구매계약을 체결한 때에는 미성년자 본인 또는 법정대리인은 회사에 그
          계약을 취소할 수 있습니다. 다만, 미성년자가 법정대리인이 범위를 정하여
          처분을 허락한 재산으로 프로그램을 구매한 경우 또는 미성년자가
          속임수로써 자기를 성년자로 믿게 하거나 법정대리인의 동의가 있는 것으로
          믿게 한 경우에는 취소할 수 없습니다.
        </li>
        <li>
          ⑨ 프로그램 구매계약의 당사자가 미성년자인지 여부는 결제가 진행된
          모바일 기기, 결제 실행자 정보, 결제 수단 명의자 등을 근거로
          판단합니다. 또한 회사는 정당한 취소인지를 확인하기 위해 미성년자 및
          법정대리인임을 증명할 수 있는 서류의 제출을 요청할 수 있습니다.
        </li>
        <li>
          ⑩ 이벤트, 프로모션 등을 통해 할인된 금액으로 프로그램을 구매한
          경우에는 환불은 불가할 수 있습니다.
        </li>
        <li>
          ⑪ 이벤트, 프로모션 등을 통해 무상으로 제공받은 경품, 포인트(적립금,
          캐쉬, 카롱 등 명칭은 불문함), 교환권, 프로그램 등의 유효기간 연장 및
          환불은 불가할 수 있습니다.
        </li>
      </ul>
      <div style={{ marginTop: 15 }}>
        <h3>제24조 (과오납금의 환급)</h3>
      </div>
      <ul>
        <li>
          ① 회사는 과오납금이 발생하는 경우 과오납금을 회원에게 환급합니다.
          다만, 과오납금이 회사의 고의 또는 과실 없이 회원의 과실로 인하여
          발생한 경우에는 그 환급에 소요되는 실제 비용은 합리적인 범위 내에서
          회원이 부담합니다.
        </li>
        <li>
          ② 애플리케이션을 통한 결제는 오픈마켓 사업자가 제공하는 결제방식에
          따르며, 결제 과정에서 과오납금이 발생하는 경우, 회사 또는 오픈마켓
          사업자에게 환급을 요청하여야 합니다.
        </li>
        <li>
          ③ 애플리케이션 등의 다운로드 또는 네트워크 서비스의 이용으로 인해
          발생한 통신요금(통화료, 데이터 통화료 등)은 환급 대상에서 제외될 수
          있습니다.
        </li>
        <li>
          ④ 환급은 서비스를 이용하고 있는 모바일 기기의 운영체제 종류에 따라 각
          오픈마켓 사업자 또는 회사의 환급정책에 따라 진행됩니다.
        </li>
        <li>
          ⑤ 회사는 과오납금의 환급을 처리하기 위해 회원에게서 제공받은 정보를
          통해 회원에게 연락할 수 있으며, 필요한 정보의 제공을 요청할 수
          있습니다. 회사는 회원으로부터 환급에 필요한 정보를 받은 날부터 3영업일
          이내에 환급합니다.
        </li>
      </ul>
      <div style={{ marginTop: 15 }}>
        <h3>제25조 (계약 해지 등)</h3>
      </div>
      <ul>
        <li>
          ① 회원은 언제든지 서비스 이용을 원하지 않는 경우 회원 탈퇴를 통해
          이용계약을 해지할 수 있습니다. 회원탈퇴로 인해 회원이 서비스 내에서
          보유한 이용정보는 이 약관이 달리 정하고 있지 않는 한 모두 삭제되어
          복구가 불가능하게 됩니다.
        </li>
        <li>
          ② 회사는 회원이 이 약관 및 그에 따른 운영정책, 서비스 정책에서
          금지하는 행위를 하는 등 본 계약을 유지할 수 없는 중대한 사유가 있는
          경우에는 상당한 기간 전에 최고하고 기간을 정하여 서비스 이용을
          중지하거나 이용계약을 해지할 수 있습니다.
        </li>
        <li>
          ③ 회사는 최근의 서비스 이용일부터 연속하여 1년 동안 회사의 서비스를
          이용하지 않은 회원(이하 “휴면계정”이라 합니다)의 개인정보를 보호하기
          위해 이용계약을 해지하고 회원의 개인정보 파기 등의 조치를 취할 수
          있습니다. 이 경우 조치일 30일 전까지 계약해지, 개인정보 파기 등의
          조치가 취해진다는 사실 및 파기될 개인정보 등을 회원에게 통지합니다.
        </li>
      </ul>
      <div style={{ marginTop: 15 }}>
        <h3>제6장 손해배상 및 면책조항 등</h3>
      </div>
      <div style={{ marginTop: 15 }}>
        <h3>제26조 (손해배상)</h3>
      </div>
      <ul>
        <li>
          ① 회사 또는 회원은 이 약관을 위반하여 상대방에게 손해를 입힌 경우에는
          그 손해를 배상할 책임이 있습니다. 다만, 고의 또는 과실이 없는 경우에는
          그러하지 아니 합니다
        </li>
        <li>
          ② 회사가 개별서비스 제공자와 제휴 계약을 맺고 회원에게 개별서비스를
          제공하는 경우에 회원이 이 개별서비스 이용약관에 동의를 한 뒤
          개별서비스 제공자의 고의 또는 과실로 인해 회원에게 손해가 발생한
          경우에 그 손해에 대해서는 개별서비스 제공자가 책임을 집니다.
        </li>
      </ul>
      <div style={{ marginTop: 15 }}>
        <h3>제27조 (회사의 면책)</h3>
      </div>
      <ul>
        <li>
          ① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할
          수 없는 경우에는 서비스 제공에 관하여 책임을 지지 않습니다.
        </li>
        <li>
          ② 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 기타 이에
          준하는 사유로 발생한 손해에 대하여 책임을 지지 않습니다. 다만, 회사의
          고의 또는 과실에 의한 경우에는 그러하지 아니합니다.
        </li>
        <li>
          ③ 회사는 회원의 고의 또는 과실로 인한 서비스 이용의 장애에 대하여는
          책임을 지지 않습니다. 다만, 회원에게 부득이하거나 정당한 사유가 있는
          경우에는 그러하지 아니합니다.
        </li>
        <li>
          ④ 회사는 회원이 다른 회원 또는 타인과 서비스를 매개로 발생한 거래나
          분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해에 대해 책임을 지지
          않습니다.
        </li>
        <li>
          ⑤ 회사는 무료로 제공되는 서비스 이용과 관련하여 회원에게 발생한 손해에
          대해서는 책임을 지지 않습니다. 그러나 회사의 고의 또는 중과실에 의한
          경우에는 그러하지 아니합니다.
        </li>
        <li>
          ⑥ 회사는 회원이 서비스를 이용하여 기대하는 이익을 얻지 못하거나 상실한
          것에 대하여 책임을 지지 않습니다.
        </li>
        <li>
          ⑦ 회사는 회원의 등급, 적립금 등의 손실에 대하여 책임을 지지 않습니다.
          다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.
        </li>
        <li>
          ⑧ 회사는 회원이 모바일 기기 비밀번호, 오픈마켓 사업자가 제공하는
          비밀번호 등을 관리하지 않아 발생하는 제3자 결제 및 회원이 계좌번호를
          오기재하여 발생하는 피해에 대해 책임을 지지 않습니다. 다만, 회사의
          고의 또는 과실에 의한 경우에는 그러하지 아니합니다.
        </li>
        <li>
          ⑨ 회원이 모바일 기기의 변경, 모바일 기기의 번호 변경, 운영체제(OS)
          버전의 변경, 해외 로밍, 통신사 변경 등으로 인해 프로그램 전부나 일부의
          기능을 이용할 수 없는 경우 회사는 이에 대해 책임을 지지 않습니다.
          다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.
        </li>
        <li>
          ⑩ 회원이 회사가 제공하는 프로그램이나 계정정보를 삭제한 경우 회사는
          이에 대해 책임을 지지 않습니다. 다만, 회사의 고의 또는 과실에 의한
          경우에는 그러하지 아니합니다.
        </li>
        <li>
          ⑪ 회사는 데모 회원이 서비스 이용으로 발생한 손해에 대해서는 책임을
          지지 않습니다. 다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지
          아니합니다.
        </li>
      </ul>
      <div style={{ marginTop: 15 }}>
        <h3>제28조 (회원에 대한 통지)</h3>
      </div>
      <ul>
        <li>
          ① 회사가 회원에게 통지를 하는 경우 회원의 전자우편주소, 전자메모,
          서비스 내 쪽지, 문자메시지(LMS/SMS) 등으로 할 수 있습니다.
        </li>
        <li>
          ② 회사는 회원 전체에게 통지를 하는 경우 7일 이상 서비스 내에
          게시하거나 팝업화면 등을 제시함으로써 제1항의 통지에 갈음할 수
          있습니다.
        </li>
      </ul>
      <div style={{ marginTop: 15 }}>
        <h3>제29조 (재판권 및 준거법)</h3>
      </div>
      <p>
        이 약관은 대한민국 법률에 따라 규율되고 해석됩니다. 회사와 회원 간에
        발생한 분쟁으로 소송이 제기되는 경우에는 법령에 정한 절차에 따른 법원을
        관할 법원으로 합니다.
      </p>
      <div style={{ marginTop: 15 }}>
        <h3>제30조 (회원의 고충처리 및 분쟁해결)</h3>
      </div>
      <ul>
        <li>
          ① 회사는 회원의 편의를 고려하여 회원의 의견이나 불만을 제시하는 방법을
          서비스 내 또는 그 연결화면에 안내합니다. 회사는 이러한 회원의 의견이나
          불만을 처리하기 위한 전담인력을 운영합니다.
        </li>
        <li>
          ② 회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로
          인정될 경우에는 합리적인 기간 내에 이를 신속하게 처리합니다. 다만,
          처리에 장기간이 소요되는 경우에는 회원에게 장기간이 소요되는 사유와
          처리일정을 서비스 내 공지하거나 제27조 제1항에 따라 통지합니다.
        </li>
        <li>
          ③ 회사와 회원 간에 분쟁이 발생하여 제3의 분쟁조정기관이 조정할 경우
          회사는 이용제한 등 회원에게 조치한 사항을 성실히 증명하고, 조정기관의
          조정에 따를 수 있습니다.
        </li>
      </ul>
    </div>
  );
};

export default TermService;
