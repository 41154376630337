import React, { useState, useEffect } from "react";

const TermLocation = () => {
  const [isRendering, setisRendering] = useState(false);

  useEffect(() => {
    setTimeout(() => setisRendering(true), 50);
  }, []);

  return (
    isRendering && (
      <div className="policyWrapper">
        <div style={{ marginTop: 8 }}>
          <h2>잘키움 위치기반서비스 이용약관</h2>
        </div>
        <div style={{ marginTop: 15 }}>
          <h3>제 1 조 (목적)</h3>
        </div>
        <p>
          본 약관은 주식회사 에이아이에스(이하 "회사")가 제공하는
          위치기반서비스에 대해 회사와 위치기반서비스를 이용하는
          개인위치정보주체(이하 "이용자")간의 권리·의무 및 책임사항, 기타 필요한
          사항 규정을 목적으로 합니다.
        </p>
        <div style={{ marginTop: 15 }}>
          <h3>제 2 조 (이용약관의 효력 및 변경)</h3>
        </div>
        <ul>
          <li>
            ① 본 약관은 이용자가 본 약관에 동의하고 회사가 정한 절차에 따라
            위치기반서비스의 이용자로 등록됨으로써 효력이 발생합니다.
          </li>
          <li>
            ② 회사는 법률이나 위치기반서비스의 변경사항을 반영하기 위한 목적
            등으로 관련법령을 위배하지 않는 범위에서 본 약관을 개정할 수
            있습니다.
          </li>
          <li>
            ③ 약관이 개정되는 경우 회사는 기존약관과 개정약관 및 개정약관의
            적용일자와 개정사유를 명시하여 현행약관과 함께 그 적용일자 10일
            전부터 적용일 이후 상당한 기간 동안 공지만을 하고, 개정 내용이
            회원에게 불리한 경우에는 그 적용일자 30일 전부터 적용일 이후 상당한
            기간 동안 각각 이를 서비스 홈페이지에 게시하거나 회원에게 전자적
            형태(전자우편, SMS 등)로 약관 개정 사실을 발송하여 고지합니다.
          </li>
          <li>
            ④ 회사가 전항에 따라 회원에게 통지하면서 공지 또는 공지∙고지일로부터
            개정약관 시행일 7일 후까지 거부의사를 표시하지 아니하면 이용약관에
            승인한 것으로 봅니다. 회원이 개정약관에 동의하지 않을 경우 회원은
            이용계약을 해지할 수 있습니다.
          </li>
        </ul>
        <div style={{ marginTop: 15 }}>
          <h3>제 3 조 (약관 외 준칙)</h3>
        </div>
        <p>
          이 약관에 명시되지 않은 사항에 대해서는 위치 정보의 보호 및 이용 등에
          관한 법률, 전기통신사업법, 정보통신망 이용 촉진 및 보호 등에 관한 법률
          등 관계법령 및 회사가 정한 지침 등의 규정에 따릅니다.
        </p>
        <div style={{ marginTop: 15 }}>
          <h3>제 4 조 (서비스의 내용)</h3>
        </div>
        <p>
          회사는 직접 수집하거나 위치정보사업자로부터 수집한 이용자의 현재 위치
          또는 현재 위치가 포함된 지역을 이용하여 아래와 같은 위치기반서비스를
          제공합니다.
        </p>

        <ul>
          <li>
            1. 위치정보를 활용한 정보 검색결과 및 콘텐츠를 제공하거나 추천
          </li>
          <li>
            2. 이용자 위치를 기준으로 주변의 농지 공유 및 위치/지역에 따른 안내
          </li>
          <li>3. 위치기반의 콘텐츠 분류를 위한 콘텐츠 태킹(Geotagging)</li>
        </ul>
        <div style={{ marginTop: 15 }}>
          <h3>제 5 조 (서비스 이용요금)</h3>
        </div>
        <ul>
          <li>
            ① 회사가 제공하는 위치기반서비스는 기본적으로 무료입니다. 단, 별도의
            유료 서비스의 경우 해당 서비스에 명시된 요금을 지불하여야 사용
            가능합니다.
          </li>
          <li>
            ② 회사는 유료 서비스 이용요금을 회사와 계약한 전자지불업체에서 정한
            방법에 의하거나 회사가 정한 청구서에 합산하여 청구할 수 있습니다.
          </li>
          <li>
            ③ 유료서비스 이용을 통하여 결제된 대금에 대한 취소 및 환불은 회사의
            결제 이용약관 등 관계법에 따릅니다.
          </li>
          <li>
            ④ 회원의 개인정보도용 및 결제사기로 인한 환불요청 또는 결제자의
            개인정보 요구는 법률이 정한 경우 외에는 거절될 수 있습니다.
          </li>
          <li>
            ⑤ 무선 서비스 이용 시 발생하는 데이터 통신료는 별도이며 가입한 각
            이동통신사의 정책에 따릅니다.
          </li>
          <li>
            ⑥MMS 등으로 게시물을 등록할 경우 발생하는 요금은 이동통신사의 정책에
            따릅니다.
          </li>
        </ul>
        <div style={{ marginTop: 15 }}>
          <h3>제 6 조 (서비스내용변경 통지 등)</h3>
        </div>
        <ul>
          <li>
            ① 회사가 서비스 내용을 변경하거나 종료하는 경우 회사는 회원의 등록된
            전자우편 주소로 이메일을 통하여 서비스 내용의 변경 사항 또는 종료를
            통지할 수 있습니다.
          </li>
          <li>
            ② 전항의 경우 불특정 다수인을 상대로 통지를 함에 있어서는 웹사이트
            등 기타 회사의 공지사항을 통하여 회원들에게 통지할 수 있습니다.
          </li>
        </ul>
        <div style={{ marginTop: 15 }}>
          <h3>제 7 조 (서비스 이용의 제한·중지)</h3>
        </div>
        <ul>
          <li>
            ① 회사는 위치기반서비스사업자의 정책변경 등과 같이 회사의 제반사정
            또는 법률상의 이유로 위치기반서비스를 유지할 수 없는 경우
            위치기반서비스의 전부 또는 일부를 제한·변경·중지할 수 있습니다.
          </li>
          <ul>
            <li>
              1. 회원이 회사 서비스의 운영을 고의 또는 중과실로 방해하는 경우
            </li>
            <li>
              2. 서비스용 설비 점검, 보수 또는 공사로 인하여 부득이한 경우
            </li>
            <li>
              3. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를
              중지했을 경우
            </li>
            <li>
              4. 국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로
              서비스 이용에 지장이 있는 때
            </li>
            <li>
              5. 기타 중대한 사유로 인하여 회사가 서비스 제공을 지속하는 것이
              부적당하다고 인정하는 경우
            </li>
          </ul>
          <li>
            ② 단, 전항에 의한 위치기반서비스 중단의 경우 회사는 사전에 회사
            홈페이지 등 기타 공지사항 페이지를 통해 공지하거나 이용자에게
            통지합니다.
          </li>
        </ul>
        <div style={{ marginTop: 15 }}>
          <h3>제 8 조 (개인위치정보주체의 권리)</h3>
        </div>
        <ul>
          <li>
            ① 이용자는 언제든지 개인위치정보의 수집·이용·제공에 대한 동의 전부
            또는 일부를 유보할 수 있습니다.
          </li>
          <li>
            ② 이용자는 언제든지 개인위치정보의 수집·이용·제공에 대한 동의 전부
            또는 일부를 철회할 수 있습니다. 이 경우 회사는 지체 없이 철회된
            범위의 개인위치정보 및 위치정보 수집·이용·제공사실 확인자료를
            파기합니다.
          </li>
          <li>
            ③ 이용자는 개인위치정보의 수집·이용·제공의 일시적인 중지를 요구할 수
            있으며, 이 경우 회사는 이를 거절할 수 없고 이를 충족하는 기술적
            수단을 마련합니다.
          </li>
          <li>
            ④ 이용자는 회사에 대하여 아래 자료에 대한 열람 또는 고지를 요구할 수
            있으며, 해당 자료에 오류가 있는 경우에는 정정을 요구할 수 있습니다.
            이 경우 정당한 사유 없이 요구를 거절하지 않습니다.
          </li>
          <ul>
            <li>1. 이용자에 대한 위치정보 수집·이용·제공사실 확인자료</li>
            <li>
              2. 이용자의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률
              또는 다른 법령의 규정에 의하여 제3자에게 제공된 이유 및 내용
            </li>
          </ul>
          <li>
            ⑤ 이용자는 권리행사를 위해 본 약관 제16조의 연락처를 이용하여 회사에
            요청할 수 있습니다.
          </li>
        </ul>
        <div style={{ marginTop: 15 }}>
          <h3>제 9 조 (개인위치정보의 이용 또는 제공)</h3>
        </div>
        <ul>
          <li>
            ① 회사는 개인위치정보를 이용하여 위치기반서비스를 제공하는 경우 본
            약관에 고지하고 동의를 받습니다.
          </li>
          <li>
            ② 회사는 이용자의 동의 없이 개인위치정보를 제3자에게 제공하지
            않으며, 제3자에게 제공하는 경우에는 제공받는 자 및 제공목적을 사전에
            이용자에게 고지하고 동의를 받습니다.
          </li>
          <li>
            ③ 회사는 개인위치정보를 이용자가 지정하는 제3자에게 제공하는 경우
            개인위치정보를 수집한 통신단말장치로 매회 이용자에게 제공받는 자,
            제공일시 및 제공목적을 즉시 통지합니다.
          </li>
          <li>
            ④ 단, 아래의 경우 이용자가 미리 특정하여 지정한 통신단말장치 또는
            전자우편주소, 온라인게시 등으로 통지합니다.
          </li>
          <ul>
            <li>
              1. 개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는
              영상의 수신기능을 갖추지 아니한 경우
            </li>
            <li>
              2. 이용자의 개인위치정보를 수집한 통신단말장치 외의 통신단말장치
              또는 전자우편주소, 온라인게시 등으로 통보할 것을 미리 요청한 경우
            </li>
          </ul>
          <li>
            ⑤ 회사는 위치정보의 보호 및 이용 등에 관한 법률 제16조 제2항에
            근거하여 개인위치정보 수집·이용·제공사실 확인자료를 자동으로
            기록·보존하며, 해당 자료는 6개월간 보관합니다.
          </li>
        </ul>
        <div style={{ marginTop: 15 }}>
          <h3>제 10 조 (법정대리인의 권리)</h3>
        </div>
        <p>
          회사는 14세 미만의 이용자에 대해서는 개인위치정보를 이용한
          위치기반서비스 제공 및 개인위치정보의 제3자 제공에 대한 동의를 이용자
          및 이용자의 법정대리인으로부터 동의를 받아야 합니다. 이 경우
          법정대리인은 본 약관 제7조에 의한 이용자의 권리를 모두 가집니다.
        </p>
        <div style={{ marginTop: 15 }}>
          <h3>제 11 조 (8세 이하의 아동 동의 보호의무자의 권리)</h3>
        </div>
        <ul>
          <li>
            ① 회사는 아래의 경우에 해당하는 자(이하 “8세 이하의 아동 등”)의
            위치정보의 보호 및 이용 등에 관한 법률 제26조2항에 해당하는 자(이하
            “보호의무자”)가 8세 이하의 아동 등의 생명 또는 신체보호를 위하여
            개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의 동의가 있는
            것으로 봅니다.
          </li>
          <ul>
            <li>1. 8세 이하의 아동</li>
            <li>2. 피성년후견인</li>
            <li>
              3. 장애인복지법 제2조제2항제2호에 따른 정신적 장애를 가진
              사람으로서 장애인고용촉진 및 직업재활법 제2조제2호에 따른
              중증장애인에 해당하는 사람(장애인복지법 제32조에 따라 장애인
              등록을 한 사람만 해당한다)
            </li>
          </ul>
          <li>
            ② 8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의
            이용 또는 제공에 동의를 하고자 하는 보호의무자는 서면동의서에
            보호의무자임을 증명하는 서면을 첨부하여 회사에 제출하여야 합니다.
          </li>
          <li>
            ③ 보호의무자는 8세 이하의 아동 등의 개인위치정보 이용 또는 제공에
            동의하는 경우 본 약관 제7조에 의한 이용자의 권리를 모두 가집니다.
          </li>
        </ul>
        <div style={{ marginTop: 15 }}>
          <h3>제 12 조 (손해배상)</h3>
        </div>
        <p>
          회사의 위치정보의 보호 및 이용 등에 관한 법률 제15조 및 26조의 규정을
          위반한 행위로 인해 손해를 입은 경우 이용자는 회사에 손해배상을 청구할
          수 있습니다. 회사는 고의, 과실이 없음을 입증하지 못하는 경우 책임을
          면할 수 없습니다.
        </p>
        <div style={{ marginTop: 15 }}>
          <h3>제 13 조 (면책)</h3>
        </div>
        <ul>
          <li>
            ① 회사는 다음 각 호의 경우로 위치기반서비스를 제공할 수 없는 경우
            이로 인하여 이용자에게 발생한 손해에 대해서는 책임을 부담하지
            않습니다.
          </li>
          <ul>
            <li>1. 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우</li>
            <li>
              2. 위치기반서비스 제공을 위하여 회사와 서비스 제휴계약을 체결한
              제3자의 고의적인 서비스 방해가 있는 경우
            </li>
            <li>
              3. 이용자의 귀책사유로 위치기반서비스 이용에 장애가 있는 경우
            </li>
            <li>
              4. 제1호 내지 제3호를 제외한 기타 회사의 고의·과실이 없는 사유로
              인한 경우
            </li>
          </ul>
          <li>
            ② 회사는 위치기반서비스 및 위치기반서비스에 게재된 정보, 자료,
            사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며 이로 인해
            발생한 이용자의 손해에 대하여는 책임을 부담하지 아니합니다.
          </li>
        </ul>
        <div style={{ marginTop: 15 }}>
          <h3>제 14 조 (규정의 준용)</h3>
        </div>
        <ul>
          <li>① 본 약관은 대한민국법령에 의하여 규정되고 이행됩니다.</li>
          <li>
            ② 본 약관에 규정되지 않은 사항에 대해서는 관련법령 및 상관습에
            의합니다.
          </li>
        </ul>
        <div style={{ marginTop: 15 }}>
          <h3>제 15 조 (분쟁의 조정 및 기타)</h3>
        </div>
        <ul>
          <li>
            ① 회사는 위치정보와 관련된 분쟁의 해결을 위해 이용자와 성실히
            협의합니다.
          </li>
          <li>
            ② 전항의 협의에서 분쟁이 해결되지 않은 경우, 회사와 이용자는
            위치정보의 보호 및 이용 등에 관한 법률 제28조의 규정에 의해
            방송통신위원회에 재정을 신청하거나, 개인정보보호법 제43조의 규정에
            의해 개인정보 분쟁조정위원회에 조정을 신청할 수 있습니다.
          </li>
        </ul>
        <div style={{ marginTop: 15 }}>
          <h3>제 16 조 (회사의 주소 및 연락처)</h3>
        </div>
        <ul>
          <li>회사의 상호, 주소 및 연락처는 아래와 같습니다.</li>
          <li>상호 : 에이아이에스 주식회사</li>
          <li>대표 : 김 민 석.</li>
          <li>주소 : 서울 강남구 테헤란로 419 파이내셜플라자 15층</li>
          <li>대표전화 : 070-5158-5952</li>
        </ul>

        <div style={{ marginTop: 15 }}>
          <h3>부칙</h3>
        </div>
        <div style={{ marginTop: 15 }}>
          <h3>제 1 조 (시행일)</h3>
        </div>
        <p>본 약관은 2021년 06월 25일부터 시행됩니다.</p>
        <div style={{ marginTop: 15 }}>
          <h3>제 2 조 (위치정보관리책임자 정보)</h3>
        </div>
        <ul>
          <li>
            회사는 개인위치정보를 적절히 관리·보호하고, 이용자의 불만을 원활히
            처리할 수 있도록 실질적인 책임을 질 수 있는 지위에 있는 자를
            위치정보관리책임자로 지정해 운영하고 있으며, 위치정보관리책임자는
            위치기반서비스를 제공하는 부서의 부서장으로서 성명과 연락처는 아래와
            같습니다.
          </li>
          <li>성명 : 김민석</li>
          <li>대표전화 : 070-5158-5952</li>
        </ul>
      </div>
    )
  );
};

export default TermLocation;
