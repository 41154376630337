import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { NdviMap } from './NdviMap';
import NotFound from '../NotFound/NotFound';
import { useHistory } from 'react-router-dom';

const Ndvi = ({ location }) => {
  const history = useHistory();
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  });
  const [isWebView, setIsWebView] = useState(false);
  const [imageUrl, setimsageUrl] = useState(null);
  const [locations, setLocations] = useState(null);
  const [isFirst, setIsFirst] = useState(true);

  const handleMessageData = (e) => {
    let data = JSON.parse(e.data);
    console.log('data');
    console.log(data);
    setimsageUrl(data.data.imageUrl);
    setLocations(data.data.location);
    setIsWebView(data.data.isMobile);
    setIsFirst(data.data.isFirst);
  };

  useEffect(() => {
    console.log('query');
    console.log(query);
    if (query) {
      const isMobile = query.isMobile;
      setIsWebView(true);
      // RN에서 웹으로 데이터를 전송했을때 message이벤트가 실행됩니다.\
      if (isMobile) {
        document.addEventListener('message', handleMessageData);

        if (!window.ReactNativeWebView) return;
        window.addEventListener('message', handleMessageData); // ios
      }
    } else {
      history.push('/');
    }

    return () => {
      document.removeEventListener('message', handleMessageData);

      if (!window.ReactNativeWebView) return;
      window.removeEventListener('message', handleMessageData);
    };
  }, [query, history]);

  const MapWrapper = () => {
    // if (locations && imageUrl && isWebView) {
    console.log(locations);
    console.log(imageUrl);
    console.log(isWebView);
    if (locations && imageUrl && isWebView) {
      return (
        <NdviMap
          isFirst={isFirst}
          isStatic
          imageUrl={imageUrl}
          locations={locations}
        />
      );
    }
    return <NotFound isWebView={isWebView} />;
  };

  return <MapWrapper />;
};

export default Ndvi;
