import React, { useState, useEffect } from "react";

const TermMarket = () => {
  const [isRendering, setisRendering] = useState(false);

  useEffect(() => {
    setTimeout(() => setisRendering(true), 50);
  }, []);

  return (
    isRendering && (
      <div className="policyWrapper">
        <div style={{ marginTop: 8 }}>
          <h2>마케팅정보 수신동의</h2>
        </div>
        <div style={{ marginTop: 15 }}>
          <p>
            개인정보보호법 제 22조 제 4항에 의해 선택정보 사항에 대해서는
            기재하지 않으셔도 서비스를 이용하실 수 있습니다
          </p>
        </div>
        <ul>
          <li>
            1. 마케팅 및 광고에의 활용- 잘키움은 고객이 수집 및 이용에 동의한
            개인정보를 활용하여 전송매체(SMS/SNS/MMS/email/App Push 등 다양한
            전송 매체)를 통하여, 잘키움 및 제 3자의상품 또는 서비스에 대한 개인
            맞춤형 광고 정보를 전송할 수 있습니다.
          </li>
          <li>
            2. 고객은 본 수신 동의를 철회하고자 할 경우 App 또는 고객센터를
            통하여 동의 철회 요청을 할 수 있습니다
          </li>
          <li>
            <table>
              <tr>
                <td
                  style={{
                    backgroundColor: "rgb(217, 226, 243)",
                    paddingLeft: 7,
                  }}
                >
                  이용항목
                </td>
                <td>나이, 성별, 연령, 휴대폰 번호, 이메일, 상담내용</td>
              </tr>
              <tr>
                <td
                  style={{
                    backgroundColor: "rgb(217, 226, 243)",
                    paddingLeft: 7,
                  }}
                >
                  이용목적
                </td>
                <td>마케팅 및 광고에의 활용</td>
              </tr>
              <tr>
                <td
                  style={{
                    backgroundColor: "rgb(217, 226, 243)",
                    paddingLeft: 7,
                  }}
                >
                  보유 및 이용기간
                </td>
                <td>
                  별도 동의 철회 시 까지 (단, 관계법령의 규정에 따라 보존할
                  필요가 있을 경우, 해당 기간까지 보존함)
                </td>
              </tr>
            </table>
          </li>
        </ul>
      </div>
    )
  );
};

export default TermMarket;
