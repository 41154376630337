import React from "react";
import "./NavBottom.css";
import { Row, Col } from "react-bootstrap";
import AisLogo from "../../asset/images/ais_bottom_logo.webp";
import AisLogo2x from "../../asset/images/ais_bottom_logo@2x.webp";
import AisLogo3x from "../../asset/images/ais_bottom_logo@3x.webp";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

const NavBottom = () => {
  const { i18n } = useTranslation();

  return (
    <div className="serviceWrapper">
      <Row className="bottomWrapper">
        <Row>
          <Col lg={5} md={4} sm={12}>
            <img
              src={AisLogo}
              srcSet={`${AisLogo2x} 2x, ${AisLogo3x} 3x`}
              alt={i18n.t("nav.home")}
            />
          </Col>
          <Col lg={7} md={8} className="menuWrapper">
            <Row>
              <Col lg={2} md={2} sm={12}>
                <div>
                  <p className="menuTitle">{i18n.t("nav.home")}</p>
                  <HashLink smooth to={`/${i18n.language}/home#`}>
                    {i18n.t("footer.aisAbout")}
                  </HashLink>
                  <HashLink smooth to={`/${i18n.language}/home#aisVision`}>
                    {i18n.t("footer.aisVision")}
                  </HashLink>
                  <HashLink smooth to={`/${i18n.language}/home#aisHistory`}>
                    {i18n.t("footer.aisHistory")}
                  </HashLink>
                  <HashLink smooth to={`/${i18n.language}/home#aisNews`}>
                    {i18n.t("footer.aisNews")}
                  </HashLink>
                  <HashLink smooth to={`/${i18n.language}/home#aisLocation`}>
                    {i18n.t("footer.aisLocation")}
                  </HashLink>
                </div>
              </Col>
              <Col lg={2} md={2} sm={12}>
                <div>
                  <p className="menuTitle">{i18n.t("nav.service")}</p>
                  <HashLink smooth to={`/${i18n.language}/service#`}>
                    {i18n.t("nav.service")}
                  </HashLink>
                </div>
              </Col>
              <Col lg={2} md={2} sm={12}>
                <div>
                  <p className="menuTitle">{i18n.t("nav.news")}</p>
                  <HashLink smooth to={`/${i18n.language}/news#`}>
                    {i18n.t("nav.news")}
                  </HashLink>
                </div>
              </Col>
              <Col lg={2} md={2} sm={12}>
                <div>
                  <p className="menuTitle">{i18n.t("nav.recruit")}</p>
                  <HashLink smooth to={`/${i18n.language}/career#`}>
                    {i18n.t("footer.aisAbout")}
                  </HashLink>
                  <HashLink smooth to={`/${i18n.language}/career#aisCulture`}>
                    {i18n.t("footer.culture")}
                  </HashLink>
                  <HashLink smooth to={`/${i18n.language}/career#aisWelfare`}>
                    {i18n.t("footer.welfare")}
                  </HashLink>
                </div>
              </Col>
              <Col lg={2} md={2} sm={12}>
                <div>
                  <p className="menuTitle">{i18n.t("nav.inquiry")}</p>
                  <HashLink smooth to={`/${i18n.language}/support#`}>
                    {i18n.t("nav.inquiry")}
                  </HashLink>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className="hr" />
        <div className="logoText">
          <p className="aisText">{i18n.t("navBottom.ais")}</p>
          <p className="aisInfotext">
            {i18n.t("navBottom.busuiness")}
            <br />
            {i18n.t("navBottom.hosting")}
            <br />
            {i18n.t("navBottom.address")}
            <br />
          </p>
          <div className="aisTerms">
            <HashLink smooth to="/terms/service#">
              <p className="aisTermsMenu">{i18n.t("navBottom.serviceTerms")}</p>
            </HashLink>
            <HashLink smooth to="/terms/info#">
              <p className="aisTermsMenu">{i18n.t("navBottom.privacyTerms")}</p>
            </HashLink>
            {/* <HashLink smooth to="/terms/location#">
              <p className="aisTermsMenu">
                {i18n.t("navBottom.locationTerms")}
              </p>
            </HashLink>
            <HashLink smooth to="/terms/market#">
              <p className="aisTermsMenu">{i18n.t("navBottom.marketTerms")}</p>
            </HashLink> */}
          </div>
        </div>
      </Row>
    </div>
  );
};

export default NavBottom;
