import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ReactHelmet from "../../components/common/ReactHelmet";
import NewsMainImg from "../../asset/images/news_main_image.jpg";
import NavBottom from "../../components/NavBottom";
import Section from "../../components/Section";

const NewsDetail = () => {
  const { i18n } = useTranslation();
  const [isRendering, setisRendering] = useState(false);

  const [news, setNews] = useState({
    title: "",
    date: "",
    content: "",
  });

  const { title, date, content } = news;
  const history = useHistory();

  useEffect(() => {
    setisRendering(true);
  }, []);

  useEffect(() => {
    setNews({
      title: "2023 정기주주총회 소집공고",
      date: "2023-03-13",
      content: `주주님의 깊은 관심과 성원에 감사드립니다.

당사 정관 제 4장 주주총회에 의거하여 2023년 정기주주총회를 다음과 같이 개최하오니 참석하여 주시기 바랍니다.

- 다 음 -

1. 일 시 : 2023년 03월 31일 (금요일) 14시
2. 장 소 : 서울시 강남구 봉은사로 439 CC빌딩 7층
3. 회의목적사항
  1) 보고사항
   - 2022년 성과보고
   - 2023년 사업계획 공유
  2) 결의사항
    - 제1호 의안 : 제6기 (2022. 1. 1 ~ 2022. 12. 31) 재무제표 승인의 건
    - 제2호 의안 : 제6기 (2022. 1. 1 ~ 2022. 12. 31) 결손금처리계산서 승인의 건
    - 제3호 의안 : 대표이사 중임의 건
4. 의결권행사에 관한 사항
    가. 주주총회에 참석하시어 의결권을 직접 행사하시거나 또는 위임 등에 의거 의결권을 간접 행사하시기 바랍니다.
    나. 주주 본인임을 확인할 수 있는 증서 [주민등록증,여권,운전면허증]를 지참 하시기 바라며, 위임 시에는 대리인의 신분증과 주주님의 인감증명서와 참석(위임)장을 함께 지참하셔야 합니다.

※ 별첨: 소집통지서 및 주주총회 참석/위임장 1부

2023년 03월 15일

서울시 강남구 봉은사로 439 CC빌딩 7층

주식회사 에이아이에스

대표이사 김 민 석
      `,
    });
  }, []);

  return (
    <>
      <ReactHelmet
        keywords={
          i18n.language === "ko"
            ? "에이아이에스(AIS), 잘키움, 새소식"
            : "에이아이에스(AIS), 잘키움, News"
        }
        description={
          i18n.language === "ko"
            ? "에이아이에스(AIS)의 새소식을 알려드립니다"
            : "News from 에이아이에스(AIS)"
        }
        title={
          i18n.language === "ko"
            ? "에이아이에스(AIS) | 새소식"
            : "에이아이에스(AIS) | News"
        }
        image={NewsMainImg}
        url={
          i18n.language === "ko"
            ? "www.aiskorea.co.kr/ko/news"
            : "www.aiskorea.co.kr/en/news"
        }
      />
      {isRendering && (
        <NewsDetailSection>
          <Section id="notices-release">
            <Section.Title
              title="공지사항"
              subTitle="에이아이에스에서 알립니다"
            >
              <NewsTitle date={date} title={title} />
              <NewsDetailContainer>{content}</NewsDetailContainer>
              <NewsButton>
                <button
                  className="list-button"
                  onClick={() => history.push(`/${i18n.language}/news#`)}
                >
                  목록
                </button>
              </NewsButton>
            </Section.Title>
          </Section>
          <NavBottom />
        </NewsDetailSection>
      )}
    </>
  );
};

const NewsTitle = ({ title, date }) => (
  <TitleContainer>
    <div>
      <h2>{title}</h2>
    </div>
    <dl>
      <dt>작성자</dt>
      <dd>관리자</dd>
      <dt>등록일</dt>
      <dd>{date}</dd>
    </dl>
  </TitleContainer>
);

export default NewsDetail;

const NewsDetailSection = styled.div`
  margin-top: 96px;
  @media screen and (max-width: 767px) {
    margin-top: 40px;
  }
`;

const TitleContainer = styled.div`
  padding: 0.5em 0;
  border-top: 1px solid #333;
  border-bottom: 1px solid #eee;

  h2 {
    color: #282828;
    font-size: 22px;
    letter-spacing: -0.6px;
    font-weight: 500;
    line-height: 32px;
    word-break: keep-all;
    margin-bottom: 1em;
  }

  dl {
    margin: 0;
    display: flex;
  }

  dt {
    display: inline-block;
    color: #777;
    font-size: 16px;
    vertical-align: middle;
    font-weight: 500;
  }

  dd {
    font-size: 16px;
    margin: 0 20px 0 5px;
    color: #888;
    font-weight: 400;
  }
`;

const NewsButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .list-button {
    color: #005500;
    background: #fff;
    border: 1px solid #005500;
    box-shadow: 0 1px 1px 0 rgb(209 213 217 / 30%);
    display: inline-block;
    width: 140px;
    padding: 14px 0;
    margin: 1.5rem 2px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
    outline: 0;
    box-sizing: border-box;
    border-radius: 4px;
    &:hover {
      background: #005500;
      color: #fff;
    }
  }
`;

const NewsDetailContainer = styled.div`
  padding: 30px 0px;
  border-bottom: 1px solid #eee;
  font-size: 16px;
  white-space: pre-line;
`;
