import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
// import { HelmetProvider } from "react-helmet";

import NavBar from "./components/NavBar";
import CompanyInfo from "./views/CompanyInfo/CompanyInfo";
import ServiceInfo from "./views/ServiceInfo/ServiceInfo";
import Recruit from "./views/Recruit/Recruit";
import Inquiry from "./views/Inquiry/Inquiry";

import TermInfo from "./views/Policy/TermInfo";
import TermService from "./views/Policy/TermService";
import TermLocation from "./views/Policy/TermLocation";
import TermMarket from "./views/Policy/TermMarket";

import ReactHelmet from "./components/common/ReactHelmet";
import mainBg from "./asset/images/jumbotron.png";
import Ndvi from "./views/Ndvi/Ndvi";
import News from "./views/News/News";
import NewsDetail from "./views/News/NewsDetail";

function App() {
  const { pathname } = useLocation();
  const pathArray = pathname.split("/");

  const { i18n } = useTranslation();

  return (
    // <HelmetProvider>
    <div className="App">
      <ReactHelmet
        keywords={
          i18n.language === "ko"
            ? "에이아이에스(AIS), 잘키움, 농업, 작물, 혁신"
            : "A에이아이에스(AIS), 잘키움, Agriculture, Crop, Innovation"
        }
        description={
          i18n.language === "ko"
            ? "에이아이에스(AIS), 작물 생육단계를 고려한 알고리즘을 통해 농가들이 변하는 환경에 대응하여 작물 수확량을 증가시키고 생산비를 감소시킬 수 있도록 최적의 농작업 처방을 제공하고 있습니다."
            : "에이아이에스(AIS), Through an algorithm that considers the stages of crop growth, we provide optimal agricultural work prescriptions so that farmers can increase crop yields and reduce production costs in response to changing environments."
        }
        title={
          i18n.language === "ko"
            ? "에이아이에스(AIS), 노지 스마트팜 서비스 '잘키움'"
            : "에이아이에스(AIS)"
        }
        image={mainBg}
        url={
          i18n.language === "ko"
            ? "www.aiskorea.co.kr/ko/home"
            : "www.aiskorea.co.kr/en/home"
        }
      />
      {pathArray[1] !== "terms" && pathArray[1] !== "ndvi" && <NavBar />}
      <Switch>
        <Route exact path={`/:lang(ko|en)/home`} component={CompanyInfo} />
        <Route exact path={`/:lang(ko|en)/service`} component={ServiceInfo} />
        <Route
          exact
          path={[`/:lang(ko|en)/career`, `/:lang(ko|en)/recruit`]}
          component={Recruit}
        />
        <Route exact path={`/:lang(ko|en)/support`} component={Inquiry} />
        <Route exact path={"/:lang(ko|en)/news"} component={News} />
        <Route
          exact
          path={"/:lang(ko|en)/news/board/:index"}
          component={NewsDetail}
        />

        <Route exact path={"/terms/info"} component={TermInfo} />
        <Route exact path={"/terms/service"} component={TermService} />
        <Route exact path={"/terms/market"} component={TermMarket} />
        <Route exact path={"/terms/location"} component={TermLocation} />
        <Route exact path={"/ndvi"} component={Ndvi} />
        {/* <Redirect from={`/:lang(ko|en)/recruit`} to={`/:lang(ko|en)/career`} /> */}
        <Redirect from={`/:lang(ko|en)/inquiry`} to={`/:lang(ko|en)/support`} />
        <Redirect exact from="/" to={`/ko/home`} />
        <Redirect to={"/ko/home"} />
      </Switch>
    </div>
    // </HelmetProvider>
  );
}

export default withTranslation()(App);
