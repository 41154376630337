import React from "react";
import { Helmet } from "react-helmet";
import faviconIcon from "./favicon.ico";
import favicon1616 from "./favicon-16x16.png";
import favicon3232 from "./favicon-32x32.png";
import appleTouch from "./apple-touch-icon.png";

import { useTranslation } from "react-i18next";

const ReactHelmet = ({ keywords, description, title, image, url }) => {
  const { i18n } = useTranslation();

  return (
    <Helmet htmlAttributes={{ lang: i18n.language }}>
      <link rel="shortcut icon" href={faviconIcon}></link>
      <link rel="icon" type="image/png" sizes="16x16" href={faviconIcon} />
      <link rel="icon" type="image/png" sizes="16x16" href={favicon1616} />
      <link rel="icon" type="image/png" sizes="32x32" href={favicon3232} />
      <link rel="apple-touch-icon" href={appleTouch} />
      <meta name="NaverBot" content="All" />
      <meta name="NaverBot" content="index,follow" />
      <meta name="Yeti" content="All" />
      <meta name="Yeti" content="index,follow" />
      <meta
        name="naver-site-verification"
        content="8a237e8b555d781b15fbc080c0417be8a5da67cd"
      />
      <meta name="keywords" content={keywords} data-react-helmet="true" />
      <meta name="description" content={description} data-react-helmet="true" />
      <meta property="og:title" content={title} data-react-helmet="true" />
      <meta property="og:url" content={url} data-react-helmet="true" />
      <meta property="og:type" content="website" data-react-helmet="true" />
      <meta property="og:site_name" content={title} data-react-helmet="true" />
      <meta property="og:image" content={image} data-react-helmet="true" />
      <meta
        property="og:description"
        content={description}
        data-react-helmet="true"
      />
      <meta name="twitter:title" content={title} data-react-helmet="true" />
      <meta
        name="twitter:description"
        content={description}
        data-react-helmet="true"
      />
      <meta name="twitter:image" content={image} data-react-helmet="true" />
      <meta name="twitter:card" content="summary" data-react-helmet="true" />
      <link rel="canonical" href={url} />
      <title>{title}</title>
    </Helmet>
  );
};

export default ReactHelmet;
