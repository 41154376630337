import React, { useState, useEffect } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import AisLogo from "../../asset/images/ais_top_logo.webp";
import AisLogo2x from "../../asset/images/ais_top_logo@2x.webp";
import AisLogo3x from "../../asset/images/ais_top_logo@3x.webp";
import "./NavBar.css";
import { useTranslation, withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const NavBar = (props) => {
  const { i18n } = useTranslation();
  const [menuActive, setMenuActive] = useState(
    window.location.pathname ? window.location.pathname : "home"
  );
  const [expanded, setExpanded] = useState(false);

  const pathArray = window.location.pathname.split("/");

  useEffect(() => {
    setMenuActive(pathArray[2]);
  }, [pathArray]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng, () => {
      let newUrl;
      if (!window.location.pathname.includes("board")) {
        newUrl = `/${lng}/${menuActive}`;
      }

      // debugger;
      props.history.replace(newUrl);
    });
    setExpanded(false);
  };

  const menuClick = (menu) => {
    setMenuActive(menu);
    setExpanded(false);
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        fixed="top"
        variant="light"
        expand="xl"
        className="navbarWrapper"
        expanded={expanded}
      >
        <Container className="navbarContainer">
          <Navbar.Brand
            as={Link}
            to={`/${i18n.language}/home#`}
            onClick={() => setMenuActive("home")}
          >
            <img
              src={AisLogo}
              srcSet={`${AisLogo2x} 2x, ${AisLogo3x} 3x`}
              alt="에이아이에스(AIS) 로고"
              className="navbarLogo"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => setExpanded(!expanded)}
          />
          <Navbar.Collapse id="responsive-navbar-nav" expanded={expanded}>
            <Nav
              defaultActiveKey={window.location.pathname}
              className="me-auto"
              onSelect={() => setExpanded(false)}
            >
              <Nav.Link
                smooth
                as={HashLink}
                to={`/${i18n.language}/home#`}
                onClick={() => menuClick("home")}
                className={menuActive === "home" ? "active" : ""}
              >
                {i18n.t("nav.home")}
              </Nav.Link>
              <Nav.Link
                smooth
                as={HashLink}
                to={`/${i18n.language}/service#`}
                onClick={() => menuClick("service")}
                className={menuActive === "service" ? "active" : ""}
              >
                {i18n.t("nav.service")}
              </Nav.Link>
              <Nav.Link
                smooth
                as={HashLink}
                to={`/${i18n.language}/news#`}
                onClick={() => menuClick("news")}
                className={menuActive === "news" ? "active" : ""}
              >
                {i18n.t("nav.news")}
              </Nav.Link>
              <Nav.Link
                smooth
                as={HashLink}
                to={`/${i18n.language}/career#`}
                onClick={() => menuClick("career")}
                className={
                  menuActive === "career" || menuActive === "recruit"
                    ? "active"
                    : ""
                }
              >
                {i18n.t("nav.recruit")}
              </Nav.Link>

              <Nav.Link
                smooth
                as={HashLink}
                to={`/${i18n.language}/support#`}
                onClick={() => menuClick("support")}
                className={menuActive === "support" ? "active" : ""}
              >
                {i18n.t("nav.inquiry")}
              </Nav.Link>
            </Nav>
            <Nav
              className="translate"
              defaultActiveKey={pathArray[1] === "en" ? "en" : "ko"}
            >
              <div
                style={{
                  display: "flex",
                  backgroundColor: "#fff",
                  alignItems: "center",
                }}
              >
                <Nav.Link
                  className="languageActive"
                  onClick={() => changeLanguage("ko")}
                  eventKey="ko"
                >
                  KOR
                </Nav.Link>
                <div style={{ color: "rgba(0,0,0,.55)" }}> | </div>
                <Nav.Link
                  className="languageActive"
                  onClick={() => changeLanguage("en")}
                  eventKey="en"
                >
                  ENG
                </Nav.Link>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default withRouter(withTranslation()(NavBar));
