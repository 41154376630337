import React, { useState, useEffect } from 'react';
import NavBottom from '../../components/NavBottom';

import styles from './companyInfo.module.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import about from '../../asset/images/about1.png';
import aboutT from '../../asset/images/about1_t.png';
import about2 from '../../asset/images/about2.png';
import about2T from '../../asset/images/about2_t.png';
import circle1 from '../../asset/images/circle1.svg';
import circle2 from '../../asset/images/circle2.svg';
import circle3 from '../../asset/images/circle3.svg';
import aisLogo from '../../asset/images/aislogo.png';
import prev from '../../asset/images/prev-arrow.svg';
import next from '../../asset/images/next-arrow.svg';
import close from '../../asset/images/close.svg';

import ReactHelmet from '../../components/common/ReactHelmet';
import mainBg from '../../asset/images/jumbotron.png';

import aisData from './aisData.json';

import Slider from 'react-slick';
import { RenderAfterNavermapsLoaded, NaverMap, Marker } from 'react-naver-maps';
import { withTranslation, useTranslation } from 'react-i18next';

import AOS from 'aos';
import 'aos/dist/aos.css';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: next,
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: prev }}
      onClick={onClick}
    />
  );
}

const CompanyInfo = () => {
  const { i18n } = useTranslation();
  const [isRendering, setisRendering] = useState(false);
  const [isPopUp, setIsPopUp] = useState(false);
  const aisNewsDataToArr = [...aisData.news];
  const aisRecruitDataToArr = [...aisData.recruit];

  useEffect(() => {
    setisRendering(true);
    AOS.init({
      duration: 2000,
    });

    if (!sessionStorage.getItem('_w_isInit')) {
      setIsPopUp(false);
    }
  }, []);

  // 모달 오버레이에서 스크롤 방지
  useEffect(() => {
    if (isPopUp) {
      document.body.style.cssText = `
      position: fixed; 
      width: 100%;`;
    } else {
      document.body.style.cssText = '';
    }

    return () => {
      document.body.style.cssText = '';
    };
  }, [isPopUp]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '60px',
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '60px',
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      <ReactHelmet
        keywords={
          i18n.language === 'ko'
            ? '에이아이에스(AIS), 잘키움, 농업, 작물, 혁신'
            : "에이아이에스(AIS), Outfield SmartFarm Service, '잘키움', Agriculture, Crop, Innovation"
        }
        description={
          i18n.language === 'ko'
            ? '에이아이에스(AIS), 작물 생육단계를 고려한 알고리즘을 통해 농가들이 변하는 환경에 대응하여 작물 수확량을 증가시키고 생산비를 감소시킬 수 있도록 최적의 농작업 처방을 제공하고 있습니다.'
            : '에이아이에스(AIS), Through an algorithm that considers the stages of crop growth, we provide optimal agricultural work prescriptions so that farmers can increase crop yields and reduce production costs in response to changing environments.'
        }
        title={
          i18n.language === 'ko'
            ? "에이아이에스(AIS), 노지 스마트팜 서비스 '잘키움'"
            : "Outfield SmartFarm Service '잘키움', 에이아이에스(AIS)"
        }
        image={mainBg}
        url={
          i18n.language === 'ko'
            ? 'www.aiskorea.co.kr/ko/home'
            : 'www.aiskorea.co.kr/en/home'
        }
      />
      {isRendering && (
        <>
          <div
            style={{
              width: '100%',
              height: '100%',
              overflow: 'hidden',
            }}
          >
            <section id='aisAbout' className={styles.jumbotron}>
              <div data-aos='fade-up' data-aos-duration='2000'>
                {i18n.language === 'ko' ? (
                  <>
                    <div className={styles.jumbotronTitle}>
                      <h2 className={styles.companyInfoTitle}>
                        {i18n.t('companyInfo.jumboTitleFirst')}
                        <br></br>
                        <strong>
                          {i18n.t('companyInfo.jumboTitleStrong')}
                        </strong>
                        {i18n.t('companyInfo.jumboTitleLast')}
                      </h2>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.jumbotronTitleEng}>
                      <h2 className={styles.companyInfoTitleEng}>
                        {i18n.t('companyInfo.jumboTitleFirst')}
                        <br></br>
                        <strong>
                          {i18n.t('companyInfo.jumboTitleStrong')}
                        </strong>
                        {i18n.t('companyInfo.jumboTitleLast')}
                      </h2>
                    </div>
                  </>
                )}
              </div>
            </section>
            <section className={styles.aisAbout}>
              <h2 className={styles.aisAboutTitle}>
                {i18n.t('companyInfo.aisAboutTitle')}
              </h2>
              <div
                className={styles.aisAboutWrapper}
                data-aos='fade-right'
                data-aos-duration='2000'
                data-aos-once='false'
              >
                <div className={styles.aisAboutImg}>
                  <img src={about} alt='about' />
                </div>
                <div className={styles.aisAboutImgT}>
                  <img src={aboutT} alt='about' />
                </div>
                <div className={styles.aisAboutContent}>
                  <h3>{i18n.t('companyInfo.aisAboutContentTitle1')}</h3>
                  <p>{i18n.t('companyInfo.aisAboutContentSub1')}</p>
                </div>
              </div>
              <div
                className={styles.aisAboutWrapper}
                data-aos='fade-left'
                data-aos-duration='2000'
                data-aos-once='false'
              >
                <div className={styles.aisAboutImg}>
                  <img src={about2} alt='about2' />
                </div>
                <div className={styles.aisAboutImgT}>
                  <img src={about2T} alt='about2' />
                </div>
                <div className={styles.aisAboutContent}>
                  <h3>{i18n.t('companyInfo.aisAboutContentTitle2')}</h3>
                  <p>{i18n.t('companyInfo.aisAboutContentSub2')}</p>
                </div>
              </div>
            </section>
            <section id='aisVision'>
              <div className={styles.outerVision}>
                <div className={styles.innerVision}>
                  <p className={styles.aisVision}>vision</p>
                  <h2 className={styles.aisVisionTitle}>
                    “{i18n.t('companyInfo.aisVisionTitle1')}
                    <br></br> {i18n.t('companyInfo.aisVisionTitle2')}”
                  </h2>
                </div>
              </div>
              <div className={styles.aisVisionsWrapper}>
                <div className={styles.aisVisionImg} id='scale'>
                  <img src={circle1} alt='scale' />
                  <p className={styles.aisVisionContent}>
                    {i18n.t('companyInfo.aisVisionContentScale1')}
                    <br></br> {i18n.t('companyInfo.aisVisionContentScale2')}
                  </p>
                </div>
                <div className={styles.aisVisionImg} id='stabilization'>
                  <img src={circle2} alt='stabilization' />
                  <p className={styles.aisVisionContent}>
                    {i18n.t('companyInfo.aisVisionContentStabilization1')}
                    <br></br>{' '}
                    {i18n.t('companyInfo.aisVisionContentStabilization2')}
                  </p>
                </div>
                <div className={styles.aisVisionImg} id='smart'>
                  <img src={circle3} alt='smart' />
                  <p className={styles.aisVisionContent}>
                    {i18n.t('companyInfo.aisVisionContentSmart1')}
                    <br></br> {i18n.t('companyInfo.aisVisionContentSmart2')}
                  </p>
                </div>
              </div>
            </section>
            <section id='aisHistory' className={styles.historyWrapper}>
              <div className={styles.aisLogoWrapper}>
                <img src={aisLogo} alt='aislogo' />
              </div>
              <h2 data-aos='fade-up' data-aos-duration='2000'>
                {i18n.t('companyInfo.aisHistoryTitle')}
              </h2>
              <div className={`${styles.history} history`}>
                <div className={styles.historyInner}>
                  <div className={styles.historyItem}>
                    <h3>2022</h3>
                    <ol>
                      <li>{i18n.t('companyInfo.aisHistoryContent20221')}</li>
                      <li>{i18n.t('companyInfo.aisHistoryContent20222')}</li>
                      <li>{i18n.t('companyInfo.aisHistoryContent20223')}</li>
                    </ol>
                  </div>
                  <div className={styles.historyItem}>
                    <h3>2021</h3>
                    <ol>
                      <li>{i18n.t('companyInfo.aisHistoryContent20211')}</li>
                      <li>{i18n.t('companyInfo.aisHistoryContent20212')}</li>
                    </ol>
                  </div>
                  <div className={styles.historyItem}>
                    <h3>2020</h3>
                    <ol>
                      <li>{i18n.t('companyInfo.aisHistoryContent20201')}</li>
                      <li>{i18n.t('companyInfo.aisHistoryContent20202')}</li>
                      <li>{i18n.t('companyInfo.aisHistoryContent20203')}</li>
                    </ol>
                  </div>
                  <div className={styles.historyItem}>
                    <h3>2019</h3>
                    <ol>
                      <li>{i18n.t('companyInfo.aisHistoryContent20191')}</li>
                      <li>{i18n.t('companyInfo.aisHistoryContent20192')}</li>
                    </ol>
                  </div>
                  <div className={styles.historyItem}>
                    <h3>2018</h3>
                    <ol>
                      <li>{i18n.t('companyInfo.aisHistoryContent20181')}</li>
                      <li>{i18n.t('companyInfo.aisHistoryContent20182')}</li>
                      <li>{i18n.t('companyInfo.aisHistoryContent20183')}</li>
                    </ol>
                  </div>
                  <div className={styles.historyItem}>
                    <h3>2017</h3>
                    <ol>
                      <li>{i18n.t('companyInfo.aisHistoryContent20171')}</li>
                      <li>{i18n.t('companyInfo.aisHistoryContent20172')}</li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>
            <section id='aisNews' className={styles.news}>
              <h2>{i18n.t('companyInfo.aisNewsTitle')}</h2>
              <div className={styles.newsSlide}>
                <Slider {...settings}>
                  {aisNewsDataToArr.reverse().map((item) => {
                    return (
                      <div key={item}>
                        <a href={item.link} target='_blank' rel='noreferrer'>
                          <div className={styles.newsItem}>
                            <div
                              className={styles.newsImgWrapper}
                              style={{
                                backgroundImage: item.image
                                  ? `url(${item.image})`
                                  : `url(${aisLogo})`,
                                backgroundSize: item.image
                                  ? 'cover'
                                  : 'contain',
                              }}
                            ></div>
                            <div className={styles.newsContent}>
                              <p>{item.title}</p>
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </section>
            <section id='aisLocation' className={styles.location}>
              <h2>{i18n.t('companyInfo.aisLocationTitle')}</h2>
              <address>{i18n.t('companyInfo.aisLocationAddress')}</address>
              <div className={styles.addressWrapper}>
                <RenderAfterNavermapsLoaded
                  ncpClientId={'wqw7xk67ax'}
                  error={<p>Maps Load Error</p>}
                  loading={<p>Maps Loading...</p>}
                >
                  <NaverMapAPI />
                </RenderAfterNavermapsLoaded>
              </div>
            </section>
          </div>
          {/* recruit */}
          {isPopUp && (
            <div className={styles.recruit_pop}>
              <div className={styles.recruit_popCon}>
                <div className={styles.recruit_popCon_wrapper}>
                  <div className={styles.recruit_row}>
                    <h2>RECRUIT</h2>

                    <img
                      src={close}
                      alt='closePopUp'
                      className={styles.recruit_close}
                      onClick={() => {
                        setIsPopUp(false);
                        sessionStorage.setItem('_w_isInit', true);
                      }}
                    />
                  </div>
                  <p>에이아이에스(AIS)의 새로운 인재를 모집합니다</p>
                  <table>
                    <thead>
                      <tr>
                        <th style={{ width: '15%' }}>직무</th>
                        <th style={{ width: '20%' }}>주요업무</th>
                        <th style={{ width: '20%' }}>지원자격</th>
                        <th style={{ width: '20%' }}>우대사항</th>
                      </tr>
                    </thead>
                    <tbody>
                      {aisRecruitDataToArr.map((item, index) => {
                        return (
                          <>
                            {item.info.map((item2, index) => {
                              return (
                                <tr>
                                  <td className={styles.recruit_center}>
                                    {item2.position}
                                    <br />
                                    {item2.position2}
                                  </td>
                                  <td>
                                    <ul>
                                      {item2.task.map((item) => {
                                        return <li>{item}</li>;
                                      })}
                                    </ul>
                                  </td>
                                  <td>
                                    <ul>
                                      {item2.requirements.map((item) => {
                                        return <li>{item}</li>;
                                      })}
                                    </ul>
                                  </td>
                                  <td>
                                    <ul>
                                      {item2.preferential.map((item) => {
                                        return <li>{item}</li>;
                                      })}
                                    </ul>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className={styles.recruit_links}>
                <a
                  className={styles.recruit_link}
                  href='https://www.saramin.co.kr/zf_user/company-info/view-inner-recruit?csn=Sk1QTzRWdFBQRk9NV0lLanVUaGNxdz09'
                  target='_blank'
                  rel='noreferrer'
                >
                  사람인 채용 바로가기
                </a>
                <a
                  className={styles.recruit_link}
                  href='https://www.wanted.co.kr/company/30913'
                  target='_blank'
                  rel='noreferrer'
                >
                  원티드 채용 바로가기
                </a>
                {/* <a
                  className={styles.recruit_link}
                  href="https://www.jobkorea.co.kr/Recruit/Co_Read/Recruit/C/ais171102"
                  target="_blank"
                  rel="noreferrer"
                >
                  잡코리아 채용 바로가기
                </a> */}
              </div>
            </div>
          )}

          <NavBottom />
        </>
      )}
    </>
  );
};

function NaverMapAPI() {
  const navermaps = window.naver.maps;

  return (
    <NaverMap
      mapDivId={'maps-getting-started-uncontrolled'} // default: react-naver-map
      style={{
        width: '100%', // 네이버지도 가로 길이
        height: '400px', // 네이버지도 세로 길이
      }}
      defaultCenter={new navermaps.LatLng(37.5115437, 127.0478224)}
      zoomControl={false}
      defaultZoom={18} // 지도 초기 확대 배율
    >
      <Marker
        key={1}
        position={new navermaps.LatLng(37.5115437, 127.0478224)}
        animation={2}
      />
    </NaverMap>
  );
}

export default withTranslation()(CompanyInfo);
